import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './lang/en'
import cnLocale from './lang/zh-cn'
import twLocale from './lang/zh-tw'
Vue.use(VueI18n)
// function setLocale() {
//   // 如果缓存中存在，则直接返回
//   const storageLocale = localStorage.getItem('esim_lang')
//   if (storageLocale) return storageLocale;
//   // 否则读取当前网页语言
//   const systemLang = (navigator.language || navigator.browserLanguage).toLowerCase()
//   const language = systemLang === 'zh-cn' ? 'zh-cn' : systemLang === 'zh-tw' ? 'zh-tw' : 'en';
//   // 设置缓存
//   localStorage.setItem('esim_lang', language)
//   return language
// }
const i18n = new VueI18n({
	// 默认语言，这里的local属性，对应message中的cn、en属性
	locale: localStorage.getItem('esim_lang')||'en',
	// 引入语言文件
	messages: {
		'zh-cn': cnLocale,
		'en': enLocale,
    'zh-tw':twLocale
	},
})
export default i18n