import { ImagePreview } from 'vant';
import i18n from '../locales';
const ApplicableDeviceImageMap = { 
  'en': ['https://esim.qn.triproaming.cn/devicesimage/device_pc_en.png'], 
  'zh-tw': ['https://esim.qn.triproaming.cn/devicesimage/device_pc_zh-tw.png'], 
  'zh-cn': ['https://esim.qn.triproaming.cn/devicesimage/device_pc_zh-cn.png'], 
};
const ActivateStepImageMap = { 
  'en': ['https://esim.qn.triproaming.cn/esimuse/pc/esim_use_en.png'], 
  'zh-tw': ['https://esim.qn.triproaming.cn/esimuse/pc/esim_use_zh-tw.png'], 
  'zh-hk':['https://esim.qn.triproaming.cn/esimuse/pc/esim_use_zh-hk.png'],
  'zh-cn': ['https://esim.qn.triproaming.cn/esimuse/pc/esim_use_zh-cn.png'], 
  'hk-tw': ['https://esim.qn.triproaming.cn/esimuse/pc/esim_use_zh-hk.png','https://esim.qn.triproaming.cn/esimuse/pc/esim_use_zh-tw.png'], 
};
function ApplicableDeviceImagePreview() {
  ImagePreview({
    images: ApplicableDeviceImageMap[i18n.locale],
    closeable: true,
  })
}
function ActivateStepImagePreview(locale) {
  ImagePreview({
    images: ActivateStepImageMap[locale],
    closeable: true,
  })
}
export {
  ApplicableDeviceImagePreview,
  ActivateStepImagePreview,
}; 