export default {
  route: {
    'home': '首页',
    'mine': '个人中心',
    'search': '覆盖超过140+国家和地区',
    'login': '登录',
    'logout': '退出登录',
    'user':'个人信息',
    'wallet':'我的钱包',
    'order':'我的订单',
    'share':'分销订单',
    'flow':'我的套餐',
    'coupon':'优惠券',
    'invite':'邀请有礼',
    'faq':'使用帮助',
    'device':'支持设备',
    'guide':'安装指引',
  },
  qrcode:{
    'lang':'切换语言',
    'title':'订单详情',
    'guide':'使用教程',
    'days': '天',
    'empty':'套餐创建中，如果您有任何疑问，请联系我们。',
    'subtitle_1':'使用eSIM方法一：扫码QR码',
    'subtitle_2':'使用eSIM方法二：手动输入QR码信息',
    'time':'(为了获得更好的服务体验 请在 {value} 前激活使用) ',
    'label_1':'SM-DP+地址',
    'label_2':'激活码',
    'label_3':'您的卡号：',
    'label_4':'订单编号：',
    'install':'快速安装'
  },
  common:{
    'qantas_title':'澳门航空独家礼遇',
    'qantas_official':'进入澳航官网了解详情',
    'qantas_get':'领取优惠',
    'general_discounts':'全场满减',
    'data_plan_offers':'套餐优惠',
    'download_tips':'请从右上角选择浏览器打开下载',
    'cancel':'取消',
    'ok':'确认',
    'all':'全部套餐',
    'hot':'热门地区',
    'area':'覆盖地区',
    'area_global':'进入官网 · 玩转地球',
    'other':'其他套餐',
    'slogan':'环旅上网，即刻连接',
    'price':'价格',
    'discount':'折扣',
    'from':'低至 ',
    'explain':'详细操作步骤',
    'title_1':'套餐规格：',
    'title_2':'套餐天数：',
    'title_3':'套餐说明',
    'title_4':'套餐介绍：',
    'title_5':'使用说明',
    'title_6':'套餐详情',
    'buy_now':'立即购买',
    'compatible_text1':'我的设备支持eSIM吗？',
    'compatible_text2':'一步查询！',
    'tips_1':'请确认设备无SIM卡限制：可于“设置 > 通用 > 关于本机” 中查看运营商锁状态；',
    'tips_2':'请确认设备支持eSIM：拨打 *#06# ，若显示“EID”序列，则代表您的移动设备支持eSIM。',
    'pay_1':'确认订单',
    'pay_2':'订单信息',
    'pay_3':'最晚使用日期：',
    'pay_4':'请选择支付方式',
    'pay_5':'应付金额：',
    'pay_6':'确认付款',
    'pay_7':'订单金额：',
    'pay_8':'订单号：',
    'pay_9':'订单提交成功，请您尽快完成付款！',
    'pay_10':'取消支付',
    'pay_11':'我已完成支付',
    'pay_12':'查询订单中，请稍候',
    'pay_13':'共减：',
    'pay_14':'商品总价：',
    'pay_15':'已优惠：',
    'search_1':'搜索关键字：',
    'search_2':'搜索结果',
    'search_3':'没有找到',
    'search_4':'请输入关键字',
    'check_order':'查看订单',
    'pay_again': '重新支付',
    'back_home':'回到首页',
    'result_success':'支付成功',
    'result_fail':'支付失败',
    'p_result_tips':'订单号: {text} eSIM配置需要1-3分钟，请耐心等待。',
    'c_result_tips':'订单号: {text} 钱包充值成功，请等待1-3分钟创建订单，感谢支持。',
  },
  reviews:{
    'title':'TraveleSIM 广受全球超1,000,000人次用户信赖',
    'add_up':'累计 6,000 多条',
    'reviews':'评论',
  },
  wallet:{
    'balance':'账户余额',
    'details':'明细',
    'card_expenses':'支出明细',
    'card_receipts':'汇入明细',
    'card_rules':'充值规则',
    'subtitle':'选择充值金额',
    'give':'送',
    'wallet_pay':'钱包支付',
    'top_up':'充值',
    'available_balance':'可用余额',
    'insufficient_funds':'余额不足',
    'transaction_details':'交易明细',
    'all':'全部',
    'expenses':'支出',
    'receipts':'汇入',
    'consent':'同意',
    'services_agreement':'TraveleSIM充值服务协议',
    'tips':'请先阅读并勾选同意相关协议与政策'
  },
  esimchange:{
    'title':'多个eSIM套餐如何切换使用？',
    'ios_1':'<span>1.</span> <span>进入<b>「设置」</b></span>',
    'ios_2':'<span>2.</span> <span>进入<b>「蜂窝网络」</b></span>',
    'ios_3':'<span>3.</span> <span>选中您想使用的 <b>eSIM</b></span>',
    'ios_4':'<span>4.</span> <span>启用，并打开<b>「数据漫游」</b></span>',
    'android_1':'<span>1.</span> <span>进入<b>「设置」</b> - <b>「移动网络」</b></span>',
    'android_2':'<span>2.</span> <span>进入<b>「SIM 卡管理」</b></span>',
    'android_3':'<span>3.</span> <span>切换<b>「默认移动数据」</b>卡</span>',
    'android_4':'<span>4.</span> <span>确认已开启<b>「数据漫游」</b></span>',
  },
  footer:{
    'name':'优游宝国际控股有限公司',
    'hk_name':'香港总部',
    'hk_addess':'香港九龙长沙湾大南西街1018号东方国际大厦11楼1103室',
    'am_name':'澳门分部',
    'am_addess':'澳门宋玉生广场336-342号诚丰商业中心17楼F/G室',
    'sz_name':'深圳分部',
    'sz_addess':'深圳市南山区科伟路3号东方科技大厦2515室',
    'tw_name':'台湾分部',
    'tw_addess':'台中市北屯区平德里永定2街35号1楼',
    'link_title':'友情链接',
    'link_yyb':'优游宝官网',
    'link_wyb':'万游宝商城',
    'link_esim':'TraveleSIM商城',
    'contact_us':'客服咨询',
    'commerce_contact':'合作咨询',
    'scan_app':'扫码下载APP',
    'scan_wechat':'扫码关注公众号',
    'download':'下载TraveleSIM APP',
    'rights':'版权所有',
  },
  coupon:{
    'go':'去使用',
    'use':'去使用',
    'check':'查看优惠券',
    'tab_1':'待使用',
    'tab_2':'已使用',
    'tab_3':'已过期',
    'tab_4':'可用优惠券',
    'tab_5':'不可用优惠券',
    'disabled':'未满使用金额',
    'expired':'优惠券已过期',
    'empty':'暂无优惠券',
    'no_available':'无可用优惠券',
    'tips':'请选择优惠券',
    'auto':'已为您自动选择最佳优惠券',
  },
  invite:{
    'mycode':'我的邀请码',
    'code':'邀请码',
    'copy':'复制',
    'share':'立即分享',
    'records':'邀请记录',
    'use':'去使用',
    'no_record':'暂无记录，快去分享你的邀请码吧~',
    'know':'我知道了',
    'now':'立即体验',
    'invited':'共邀请好友',
    'coupons':'共获得优惠券（张）',
    'rules':'活动规则',
    'text_1':'分享邀请码，领取优惠券',
    'text_2':'分享邀请码',
    'text_3':'邀请码，推荐好友下载使用TraveleSIM。',
    'text_4':'好友注册',
    'text_5':'好友使用您的邀请码完成注册，可获$1奖券。（仅活动期间）',
    'text_6':'获得优惠券',
    'text_7':'好友完成注册后，您将获得$1优惠券。',
    'text_8':'分享文案已复制',
    'text_9':'文案已复制，快去分享给你的好友，一起体验eSIM吧~',
  },
  login: {
    'invite': '使用邀请码注册，可获得优惠券',
    'reset':'密码不一致，请重新输入',
    'logout_title':'退出登录',
    'logout_text':'确认退出登录？',
    'logout_ok':'退出登录',
    'title_1':'登录',
    'title_2':'注册',
    'title_3':'忘记密码',
    'title_4':'绑定账号',
    'tips_0':'请输入邮箱地址',
    'tips_1':'请输入手机号码',
    'tips_2':'请再次确认密码',
    'tips_3':'请输入密码',
    'tips_4':'请输入验证码',
    'tips_5':'获取验证码',
    'tips_6':'已有账号，',
    'tips_7':'马上登录',
    'tips_8':'请输入新的账号密码',
    'tips_9':'请再次确认新的账号密码',
    'tips_10':'返回登录',
    'tips_11':'请输入邀请码（可选填）',
    'tips_12':'秒后重新发送',
    'tips_13':'请输入手机号码/邮箱地址',
    'tips_14':'请输入手机区号',
    'tips_15':'请输入正确邮箱地址',
    'tips_16':'请输入正确手机号码',
    'tips_17':'请输入6-16位数字字母组合密码',
    'area':'请选择区号',
    'treaty_1':'《用户协议》',
    'treaty_2':'《隐私政策》',
    'text_1':'输入注册账号，点击“登录”，即代表您同意我们的',
    'text_2':'为了您的账号安全，请验证邮箱',
    'text_3':'为了您的账号安全，请验证手机',
    'text_4':'输入邮箱地址，注册TraveleSIM账号。点击“注册”，即代表您同意我们的',
    'text':'和',
    'tab_1':'或手机',
    'tab_2':'或邮箱',
    'other':'第三方账号'
  },
  mine:{
    'ios_title':'iOS 教程',
    'android_title':'安卓教程',
    'tab_1':'全部',
    'tab_2':'待付款',
    'tab_3':'待使用',
    'tab_4':'使用中',
    'tab_5':'已过期',
    'tab_6':'剩余付款时间：',
    'user_1':'账号：',
    'user_2':'登录密码',
    'user_3':'设置',
    'user_4':'修改密码',
    'user_5':'绑定邮箱',
    'user_6':'已绑定',
    'user_7':'未绑定',
    'user_8':'修改',
    'user_9':'绑定',
    'user_10':'绑定手机',
    'user_11':'请输入旧密码',
    'user_12':'请输入新密码',
    'user_13':'请输入邮箱地址',
    'user_14':'请输入手机号码',
    'user_15':'分享链接',
    'user_16':'复制',
    'user_17':'设置密码',
    'user_18':'分享二维码',
    'order_1':'地区套餐',
    'order_2':'下单时间',
    'order_3':'实付款',
    'order_4':'订单状态',
    'order_5':'支付方式',
    'order_6':'操作',
    'order_7':'订单编号',
    'order_8':'立即支付',
    'order_9':'实付：',
    'order_10':'暂无订单',
    'order_11':'优惠券',
    'data':'可用流量',
    'flow_1':'使用中',
    'flow_2':'待激活',
    'flow_3':'复制成功',
    'flow_4':'复制失败',
    'flow_5':'流量已使用：',
    'flow_6':'激活时间',
    'flow_7':'过期时间',
    'flow_8':'使用eSIM方法一：扫码QR码',
    'flow_9':'使用eSIM方法二：手动输入QR码信息',
    'flow_10':'激活码',
    'flow_11':'安装指引',
    'flow_12':'再次购买',
    'flow_13':'于{msg}过期',
    'flow_14':'暂无套餐',
    'flow_15':'SM-DP+地址',
    'flow_16':'已过期',
    'flow_17':'数据显示有一定延迟，请以实际使用情况为准。',
    'share_0':'请选择日期',
    'share_1':'创建时间',
    'share_2':'订单号',
    'share_3':'请输入订单号',
    'share_4':'状态',
    'share_5':'请选择状态',
    'share_6':'已支付',
    'share_7':'已退款',
    'share_8':'导出',
    'share_9':'重置',
    'share_10':'搜索',
    'share_11':'账号',
    'share_12':'数量',
    'share_13':'付款金额',
    'share_14':'佣金',
    'share_15':'类型',
    'share_16':'来源',
    'share_17':'佣金比例',
  },
  http: {
    'timeout':'服务器响应超时，请刷新当前页',
    'fail': '数据获取失败',
    'server_fail':'连接服务器失败',
    'err': '请求接口失败',
    '400': '请求错误',
    '404': '请求错误,未找到该资源',
    '500':'服务器端出错',
    '502':'网络错误',
    '504':'网络超时',
  },
  user:{
    'top_up_title':'TraveleSIM充值服务协议',
    'top_up_rules':`
    <p>
      TraveleSIM（以下简称“本平台”）按照《充值服务协议》（以下简称“本协议”）的规定提供基于互联网的充值服务，为获得充值服务，服务使用人（以下称“用户”或“您”）同意本协议的全部条款并按照页面上的提示完成全部的充值程序。
    </p>
    <p>
      用户在使用本平台提供的各项服务之前，应仔细阅读本服务协议，特别是免除或者限制责任的条款。<span>如用户不同意本协议及/或对其的修改，请停止使用本平台提供的充值服务</span>，用户在进行充值程序过程中使用充值服务即表示用户完全接受本协议项下的全部条款。
    </p>
    <p>
      <span>特别提示：本平台坚决反对未成年人在未经监护人同意的情况下使用充值服务，未成年人请委托监护人操作或在监护人明示同意下操作，否则请勿使用本充值服务。</span>
    </p>
    <p class="subtitle">
      1.服务说明
    </p>
    <p>
      1.1.用户可通过本平台充值服务进行充值，用以购买消费 <b>本平台</b>相关产品或服务。
    </p>
    <p>
      1.2.用户充值成功后，可在“<b>个人中心-我的钱包</b>”中查看账户余额。
    </p>
    <p>
      1.3.用户在购买产品时，可于订单支付页面选择使用钱包余额进行支付，具体交易明细请进入“<b>个人中心-我的钱包-支出明细/汇入明细</b>”中查看。
    </p>
    <p>
      1.4.用户如需退款，需在充值成功后的<b>180天内（6个月内）</b>与本平台客服联系进行申请。<span>充值金额的未使用部分可以申请退款，可退款金额的25%将作为手续费扣除。</span>扣除手续费后剩余的金额将在申请后3个工作日内全部退还。
    </p>
    <p class="subtitle">
      2.服务使用规则（重要条款）
    </p>
    <p>
    2.1.充值真实准确性
    </p>
    <ul>
      <li>
      2.1.1.您可以在充值服务界面上自由选择具体的充值方式，并按页面提示的程序完成充值。<span>请您在充值前，务必仔细核账户ID、支付方式、支付金额等具体信息</span>。
      </li>
      <li>
      2.1.2.您在选择充值金额，使用具体充值方式进行充值成功后，将会收到对应的可用金额（即账户余额）。充值成功后请保留充值订单号以作为今后发生问题时可核对之依据凭证（用户就充值服务投诉，但无法提供有效凭证作为佐证的，本平台将不会作出补偿或赔偿）。
      </li>
      <li>
      若因为用户自身输入账号错误、操作不当或不按照充值流程操作等因素造成选错支付方式、充错金额、发生充值错误、充错账号等情形而损害自身权益的，<span>本平台将不会作补偿或赔偿。</span>
      </li>
    </ul>
    <p>
    2.2.充值合法正当性
    </p>
    <ul>
      <li>
      2.2.1.用户必须遵循本协议规则及法律法规，严格按照有关监管部门规定使用本平台提供的充值服务，如用户在充值时使用第三方支付机构提供的服务，还应当遵守该第三方机构的各项协议及其服务规则。
      </li>
      <li>
      2.2.2.您应保证并承诺，<span>在本平台充值使用的资金来源为您的合法财产，且充值行为不存在任何违法犯罪目的。</span>如您充值所用资金被国家司法机关、执法机关认定为非法并导致相关司法机关、执法机关对本平台银行账户采取冻结、划扣在内等强制措施，所造成的一切损失由您负责向本平台赔偿，包括但不限于本平台因主张权利而支出的公证费、诉讼费、保全费、律师费以及被强制划扣的全部金额。
      </li>
      <li>
      2.2.3.请您知悉，若您以非法的方式，或使用非本平台所指定的充值方式进行充值，本平台不保证该充值顺利或者正确完成。因此造成用户权益受损的，本平台不会作补偿或赔偿；同时本平台保留随时冻结、注销该用户账号以及追究平台所遭受损失的权利。
      </li>
      <li>
      2.2.4.用户不得通过本平台充值进行任何违法犯罪活动（如洗钱、套现等），不得利用充值漏洞谋利，否则本平台有权终止服务，追缴非法获利；情节严重的，依法移交司法机关。
      </li>
    </ul>
    <p>
    2.3.充值安全规范
    </p>
    <ul>
      <li>
      2.3.1.用户请知悉，在使用充值服务过程中您应妥善保管自身个人信息，包括但不限于银行账号、密码、验证码等。
      </li>
      <li>
      2.3.2.本平台禁止用户盗用、冒用他人平台账号进行充值或使用服务等功能，如经发现，本平台将暂停或终止该账户部分或全部功能及服务，且不退还或支付任何款项。
      </li>
    </ul>
    <p class="subtitle">
      3.协议变更及不可抗力
    </p>
    <p>
      3.1.您理解并认可，本平台可根据国家法律法规变化或本平台服务变化的需要更新或修改本协议，并通过合理的方式向您送达修改通知，包括但不限于系统通知、弹窗消息推送等，如您对变更事项不同意的，您应当于变更事项确定的生效之日起停止使用本平台服务；如您在变更事项生效后仍继续使用本平台服务，则视为您同意已生效的变更事项。
    </p>
    <p>
    3.2.<span>由不可抗力及不可预见的情势导致的各种情况和纠纷，本平台将尽可能提前通知您，您理解并同意平台对此无需承担责任。</span>不可抗力和不可预见情势包括但不限于：黑客攻击、电信部门技术调整导致的重大影响、政府管制导致的暂时关闭、病毒侵袭。
    </p>
    <p>
    <span>在此，您再次保证已经完全阅读并充分理解了本协议条款，接受上述所有条款的约束。</span>
    </p>
    `,
    'privacy_title':'TraveleSIM隐私政策',
    'privacy':`
    <p>TraveleSIM遵守适用的数据保护及隐私法律和规例（“适用法律”）。在适用法律允许的最大范围内，及受用户在适用法律要求下的有效同意所规限，用户在使用TraveleSIM软件、服务、网站或任何其他提供TraveleSIM服务的第三方平台，即表示同意及接受本隐私政策。我们可能不时更新本隐私政策以反映我们的软件或商品的改变和用户意见，或适用法律的改变。如本隐私政策或对于我们將如何使用用户的个人资料之方式有重大改变时，我们会在实施该等改变之前以明显的方式刊登有关通知或直接向用户发出通知。我们鼓励用户定期细阅读本隐私政策，以得知我们正如何收集，使用及保护用户的资料。</p>
    <p class="subtitle">1. 我们收集的个人信息</p>
    <p>我们可能会不时向用户收集个人资料或其他信息，以便向用户提供我们的产品或服务。我们所收集的信息包括但不限于： </p>
    <p>(a)用户在注册时所提供的资料，或是稍后显示在TraveleSIM软件用户端、TraveleSIM线上搜寻目录以及在TraveleSIM软件的个人中心页面的TraveleSIM个人资料。其中可能包括用户的TraveleSIM手机号码等，以及用户同意提供的所有其他资料； </p>
    <p>(b)身份识别资料（如手机号码、邮箱）； </p>
    <p>(c)电子识别资料（如IP地址、cookies）； </p>
    <p>(d)有关用户使用我们的软件、服务、产品或网页互动的资讯（包括电脑、用户端资讯、错误报告、页面浏览统计资料、浏览器类型和使用统计资料)；  </p>
    <p>(e)流量数据（为传送通讯或计费而处理的数据）； </p>
    <p>(f)付款详情，包括收件人信息及支付方式； </p>
    <p>(g)联系人详情，包括联系人姓名、电话号码等。 </p>
    <p>此外，用户的线上时段资料可能会间接地以统计和匿名的方式被收集。 </p>
    <p>如用户未满18岁时，用户需在家长或监护人的同意和监督下提供个人资料。 </p>
    <p>为了实现【第三方平台登录】功能，我们使用了Google 的OAuth产品。参照如下：</p>
    <p>Google 的 API 服务条款：<a href="https://developers.google.com/terms" target="_blank">https://developers.google.com/terms</a></p>
    <p>Google API 服务用户数据政策：<a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank">https://developers.google.com/terms/api-services-user-data-policy</a></p>
    <p class="subtitle">2.用户个人信息的使用</p>
    <p>我们收集信息的主要目的是为用户提供安全、顺畅、有效率的体验。受用户的有效同意所规限，我们于取得许可或必要的情况下会收集和使用、或委托第三方服务供应者代表我们收集和使用有关用户的个人资料，如 ： </p>
    <p>(a)接受、处理或传送经由我们的网站产生的订单，开立发票给用户或通知用户订单状态； </p>
    <p>(b)为用户提供其他服务（如我们收集资讯时所述）；</p>
    <p>(c)为用户提供客户紧急救援及其它疑难排解服务；</p>
    <p>(d)比较资讯的准确性；</p>
    <p>(e)通知用户关于服务更新与故障的资讯；</p>
    <p>(f)解决争议；</p>
    <p>(g)验证用户的身份；</p>
    <p>(h)改善我们的软体、服务或网站； </p>
    <p>(i)筹划与执行我们的营销或推广活动、优惠和类似活动；</p>
    <p>(j)通过广告支持，免费提供某些软件、功能及网站；</p>
    <p>(k)针对故障和服务问题与用户联络，传送相关讯息；</p>
    <p>(l)以电子邮件、软件或短信进行通知，使用户知悉关于使用TraveleSIM软件、产品或网站的最终求偿资讯，包括但不限于侵犯第三方权利的侵权求偿； </p>
    <p>(m)使用短信或TraveleSIM消息去通知用户TraveleSIM软件、服务或网站的最新信息，及告知用户我们的相关推广。</p>
    <p class="subtitle">3.用户个人信息的披露及转移</p>
    <p>除了以下所述之外，在未获得用户的有效及明确许可下，TraveleSIM不会销售、出租、交易或转让任何个人流量数据或通讯内容，除非TraveleSIM在适用法律或管辖机构的命令下有义务作出上述行为。 </p>
    <p>    TraveleSIM可能披露个人信息以回应法律规定，行使我们的法律权利或于索偿中抗辩，以保护TraveleSIM的利益，打击诈欺行为及执行我们的政策或保护用户的合法权利、财产或安全。 </p>
    <p>    TraveleSIM、TraveleSIM的合作伙伴或促进用户的通讯运营商或公司可提供个人资料、通讯内容或流量资料給适当司法、执法或合法地要求该等信息的政府机构。受用户的有效及明确同意所限，TraveleSIM可能将用户的个人资料提供给供应商或TraveleSIM的关联公司。</p>
    <p class="subtitle">4. 用户的个人信息安全及保存</p>
    <p>我们会在用户是我们客户期间或用户已不再使用我们的服务之后储存用户的个人信息，但此仅为有必要的或者因应法律要求。我们将严格执行相关法律下的适用义务和例外规定，采取适当的组织性和技术性措施保护向其提供或所收集的个人资料和流量资料。用户的个人资料和流量资料仅供经许可员工或第三方存取，并且只会在必要时才保存至达到履行其收集的原来目的或直接相关的目的时，个人资料和流量资料被保存以符合任何适用法律或合同义务除外。</p>
    <p class="subtitle">5.查阅或改正数据</p>
    <p>用户有权查阅用户的个人信息，并在相关的情况下加以改正。 </p>
    <p>除非法律要求保留或因为合法的商业目的，我们将尽合理努力满足删除个人资讯的要求。 </p>
    <p>    根据现行法律，我们保留对处理任何查阅资料请求而收取合理费用的权利。</p>
    <p class="subtitle">6.手机通知服务</p>
    <p>当用户的手机使用TraveleSIM时，我们可能会在用户没有执行或没有使用TraveleSIM应用的情况下，利用该手机的通知服务让用户得知资讯。这些服务项目可能由第三方提供。例如，传送到Apple装置的讯息会使用Apple的紧急通知服务。 </p>
    <p>    通知服务可能收到关于来电者、讯息发送者和讯息内容的资讯以提供服务，并可能根据服务提供者的条款和细则及隐私权原则来使用这些资讯。 </p>
    <p>(Apple为AppleInc.在美国及其他国家的注册商标。)</p>
    <p class="subtitle">7.联系我们</p>
    <p>如用户对本隐私政策有任何查询，请在TraveleSIM应用内联系我们客服。</p>`, 
    'treaty_title':'用户注册协议',
    'treaty':`
    <P>TraveleSIM提醒您（用户）在注册成为用户之前，请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您完整阅读并选择是否接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。</P>
    <P>本协议约定TraveleSIM与用户之间关于TraveleSIM软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。</P>
    <P class="subtitle">一、 账号注册</P>
    <P>1、 用户在使用本服务前需要注册一个TraveleSIM账号。TraveleSIM账号应当使用手机号码/邮箱注册，请用户使用尚未注册的手机号码或邮箱账号注册。TraveleSIM可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，无须事先通知用户。</P>
    <P>2、 TraveleSIM系基于地理位置的移动互联网产品，用户注册时应当授权TraveleSIM公开及使用其方可成功注册TraveleSIM账号。故用户完成注册即表明用户同意TraveleSIM提取、公开信息。</P>
    <P>3、 鉴于TraveleSIM账号的绑定注册方式，TraveleSIM在用户注册时将自动提取用户手机号码及手机设备识别码等信息。</P>
    <P>4、 在用户注册及使用本服务时，TraveleSIM需要搜集能识别用户身份的个人信息以便TraveleSIM可以在必要时联系用户，或为用户提供更好的使用体验。</P>
    <P class="subtitle">二、 服务内容</P>
    <P>本服务的具体内容由TraveleSIM根据实际情况提供，TraveleSIM可以对其提供的服务予以变更，服务变更将在更新版本时一并提供。</P>
    <P class="subtitle">三、 用户个人隐私信息保护</P>
    <P>1、 用户在注册账号或使用本服务的过程中，可能需要填写或提交一些必要的信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。</P>
    <P>2、 个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如，用户真实姓名、手机号码、手机设备识别码、IP地址。非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在TraveleSIM服务器端的基本记录信息、个人隐私信息范围外的其他普通信息，以及用户同意公开的上述隐私信息。</P>
    <P>3、 TraveleSIM尊重用户个人隐私信息的私有性，确保个人隐私信息安全，防止在本服务中收集的用户个人隐私信息泄露、毁损或丢失。在发生前述情形或者TraveleSIM发现存在发生前述情形的可能时，将及时采取补救措施。</P>
    <P>4、 TraveleSIM未经用户同意不向任何第三方公开、透露用户个人隐私信息，但以下特定情形除外：</P>
    <P>（1）TraveleSIM根据法律法规规定或有权机关的指示提供用户的个人隐私信息；</P>
    <P>（2）由于用户与他人共享注册账户，由此导致的任何个人信息的泄露，或其他非TraveleSIM原因导致的个人隐私信息的泄露；</P>
    <P>（3）用户自行向第三方公开其个人隐私信息；</P>
    <P>（4）用户与TraveleSIM及合作单位之间就用户个人隐私信息的使用公开达成约定，TraveleSIM因此向合作单位公开用户隐私信息；</P>
    <P>（5）为防止冒用他人的身份进行注册，本软件将通过短信方式来验证手机号码，注册或邀请所产生的短信费用将由运营商收取；</P>
    <P>（6）任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露。</P>
    <P>5、用户同意TraveleSIM可在以下事项中使用用户的个人信息：</P>
    <P>（1）TraveleSIM向用户及时发送重要通知，如软件更新、本协议条款的变更；</P>
    <P>（2）TraveleSIM由内部进行审计、数据分析和研究等，以改进TraveleSIM的产品、服务和用户之间的沟通；</P>
    <P>（3）依本协议约定，TraveleSIM管理、审查用户信息及进行处理措施；</P>
    <P>（4）适用法律法规规定的其他事项。</P>
    <P>除上述事项外，如未取得用户事先同意，TraveleSIM不会将用户个人隐私信息适用于任何其他用途。</P>
    <P>6、TraveleSIM重视对未成年人个人隐私信息的保护。TraveleSIM将依赖用户提供的个人信息判断用户是否为未成年人。任何18岁以下的未成年人注册账号或使用本服务应事先取得家长或其法定监护人（以下简称“监护人”）的同意。除根据法律法规的规定及有权机关的指示披露外，TraveleSIM不会使用或向任何第三方透露未成年人的个人隐私信息。</P>
    <P>7、用户确认：地理位置信息为非个人隐私信息，用户成功注册TraveleSIM账号视为确认授权TraveleSIM自由提取。</P>
    <P>8、为了改善TraveleSIM的技术和服务，向用户提供更好地服务体验，TraveleSIM会自行收集使用或向第三方提供用户的非个人隐私信息。</P>
    <P class="subtitle">四、 内容规范</P>
    <P>1、 本条所述内容是指用户使用本服务过程中所制作、上传、复制、发布、传播的任何内容，包括但不限于账号头像、名称等注册信息及认证资料，以及其他使用账号或本服务所产生的内容。</P>
    <P>2、 用户不得利用TraveleSIM账号或本服务制作、上传、复制、发布、传播如下法律、法规和政策禁止的内容：</P>
    <P>（1）反对宪法所确定的基本原则的；</P>
    <P>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</P>
    <P>（3）损害国家荣誉和利益的；</P>
    <P>（4）煽动民族仇恨、民族歧视、破坏民族团结的；</P>
    <P>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</P>
    <P>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</P>
    <P>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</P>
    <P>（8）侮辱或者诽谤他人，侵害他人合法权益的；</P>
    <P>（9）含有法律、行政法规禁止的其他内容的信息。</P>
    <P>3、 用户不得利用TraveleSIM账号或本服务制作、上传、复制、发布、传播如下干扰TraveleSIM正常运营，以及侵犯其他用户或第三方合法权益的内容：</P>
    <P>（1）含有任何性或性暗示的；</P>
    <P>（2）含有辱骂、恐吓、威胁内容的；</P>
    <P>（3）含有骚扰、垃圾广告、恶意信息、诱骗信息的；</P>
    <P>（4）涉及他人隐私、个人信息或资料的；</P>
    <P>（5）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</P>
    <P>（6）含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权内容的信息。</P>
    <P class="subtitle">五、 使用规则</P>
    <P>1、 用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表TraveleSIM的观点、立场或政策，TraveleSIM对此不承担任何责任。</P>
    <P>2、 用户不得利用TraveleSIM账号或本服务进行如下行为：</P>
    <P>（1）提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；</P>
    <P>（2）强制、诱导其他用户关注、点击链接页面或分享信息的；</P>
    <P>（3）虚构事实、隐瞒真相以误导、欺骗他人的；</P>
    <P>（4）利用技术手段批量建立虚假账号的；</P>
    <P>（5）利用TraveleSIM账号或本服务从事任何违法犯罪活动的；</P>
    <P>（6）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否具有商业目的；</P>
    <P>（7）其他违反法律法规规定、侵犯其他用户合法权益、干扰TraveleSIM正常运营或TraveleSIM未明示授权的行为。</P>
    <P>3、 用户须对利用TraveleSIM账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等安全负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与TraveleSIM无关。如因此给TraveleSIM或第三方造成损害的，用户应当依法予以赔偿。</P>
    <P>4、 TraveleSIM提供的服务中可能包括广告、用户在使用过程中显示TraveleSIM和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对该广告信息进行的交易负责，对用户因该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，TraveleSIM不承担任何责任。</P>
    <P class="subtitle">六、 账号管理</P>
    <P>1、TraveleSIM账号的所有权归TraveleSIM所有，用户完成申请注册手续后，获得TraveleSIM账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。TraveleSIM因经营需要，有权回收用户的账号。</P>
    <P>2、用户可以更改、删除TraveleSIM账户上的个人资料、注册信息及传送内容等，但需注意，删除有关信息的同时也会删除用户储存在系统中的文字和图片。用户需承担该风险。</P>
    <P>3、用户有责任妥善保管注册账号信息的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册账号行为承担法律责任。用户同意在任何情况下不使用其他用户的账号。在用户怀疑他人使用其账号时，用户同意立即通知TraveleSIM。</P>
    <P>4、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，TraveleSIM有权依据协议中断或终止对违约用户的TraveleSIM账号提供服务。同时，TraveleSIM保留在任何时候收回账号的权利。</P>
    <P>5、如用户注册账号后一年不登录，TraveleSIM可以收回该账号，以免造成资源浪费，由此造成的不利后果由用户自行承担。</P>
    <P class="subtitle">七、 数据储存</P>
    <P>1、TraveleSIM不虚构、不自行上传内容，所有数据均由用户主动上传。TraveleSIM仅承担存储者的角色。</P>
    <P>2、TraveleSIM不对用户在本服务中相关数据的删除或储存失败负责。</P>
    <P>3、TraveleSIM可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本服务中的相关数据。</P>
    <P>4、如用户停止使用本服务或本服务终止，TraveleSIM可以从服务器上永久地删除用户的数据。本服务停止、终止后，TraveleSIM没有义务向用户返还任何数据。</P>
    <P class="subtitle">八、 用户注意事项</P>
    <P>1、用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户同意赔偿本公司与合作公司、关联公司，并使之免受损害。</P>
    <P>2、本软件涉及到WiFi联网和Internet服务，可能会受到各个环节不稳定因素的影响。因此服务存在因上述不可抗力、病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、GSM网络、互联网络、通信线路原因等造成服务中断或不能满足用户要求的风险。开通服务的用户须承担以上风险，服务方对服务的及时性、安全性、准确性不做担保，对因此导致的用户通信不畅，不承担任何责任。</P>
    <P>3、对于因不可抗力而造成的服务器的死机、网络的中断，以及软件升级时的服务暂停等，进而对本服务用户造成的损失，服务方不承担任何责任。</P>
    <P>4、TraveleSIM提供断网情况下的自动连接，用户注册使用，则视为已同意接受此服务，由此产生的数据流量费用由用户自行承担。</P>
    <P>5、用户应提供和分享自有WiFi热点信息，或保证分享的其他热点（包括但不限于密码和地理位置等）在热点提供者的许可范围内，并保证分享的所有WiFi热点的信息安全。由于违反本条而引发的问题，TraveleSIM不承担任何责任。</P>
    <P>6、用户应当确保将由TraveleSIM获取的热点信息用于恰当的用途，严禁任何非法或违背相关准则规定的行为。由此引发的问题，与TraveleSIM无关。</P>
    <P>7、热点提供者有权利要求从TraveleSIM的数据库中剔除由其提供的热点信息。TraveleSIM将按照法律规定予以相应处理。</P>
    <P class="subtitle">九、 法律及争议解决 </P>
    <P>1、 本协议适用中华人民共和国法律。</P>
    <P>2、 因本协议引起的或与本协议有关的任何争议，各方应友好协商解决；协商不成则任何一方均可将有关争议提交至深圳仲裁委员会并按照其届时有效的仲裁规则仲裁；仲裁裁决是终局的，对各方均有约束力。</P>
    <P class="subtitle">十、 其他条款</P>
    <P>1、 如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款被视为删除，但本协议的其余条款仍应有效并且有约束力。</P>
    <P>2、 TraveleSIM有权随时根据有关法律、法规的变化以及公司经营状况和经营策略的调整等修改本协议。修改后的协议会随附于新版本软件发布。当发生有关争议时，以最新的协议文本为准。如果不同意改动的内容，用户可以自行删除本软件。如果用户继续使用本软件，则视为您接受本协议的变动。</P>
    <P>3、 TraveleSIM在法律允许的最大范围内对本协议拥有解释权与修改权。</P>`
  }
}