export function validatorEmail(value){
  const regex = /^([a-zA-Z0-9._%-]+)@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,6})$/
  return regex.test(value.trim())
}
export function validatorMobile(value){
  const regex = /^\d{6,14}$/
  return regex.test(value.trim())
}
export function validatorPassword(value){
  const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,16}$/
  return regex.test(value)
}