import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title_cn: '首页',
      title_en: 'TraveleSIM',
      title_tw: '首頁',
    }
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      title_cn: '搜索',
      title_en: 'Search',
      title_tw: '搜索',
    },
    component: () => import('../views/Search.vue')
  },
  {
    path: '/mine',
    name: 'Mine',
    redirect: '/mine/user',
    component: () => import('../views/Mine.vue'),
    children: [
      {
        path: '/mine/user',
        name: 'User',
        meta: {
          requiresAuth: true,
          title_cn: '个人信息',
          title_en: 'Account',
          title_tw: '個人信息',
        },
        component: () => import('../views/User.vue'),
      },
      {
        path: '/mine/wallet',
        name: 'Wallet',
        meta: {
          requiresAuth: true,
          title_cn: '我的钱包',
          title_en: 'My Orders',
          title_tw: '我的訂單',
        },
        component: () => import('../views/Wallet.vue'),
      },
      {
        path: '/mine/order',
        name: 'Order',
        meta: {
          requiresAuth: true,
          title_cn: '我的订单',
          title_en: 'My Orders',
          title_tw: '我的訂單',
        },
        component: () => import('../views/Order.vue'),
      },
      {
        path: '/mine/share',
        name: 'Share',
        meta: {
          requiresAuth: true,
          title_cn: '分销订单',
          title_en: 'Distribution Order',
          title_tw: '分銷訂單',
        },
        component: () => import('../views/Share.vue'),
      },
      {
        path: '/mine/esims',
        name: 'Esims',
        meta: {
          requiresAuth: true,
          title_cn: '我的套餐',
          title_en: 'My eSIMs',
          title_tw: '我的套餐',
        },
        component: () => import('../views/Esims.vue'),
      },
      {
        path: '/mine/coupon',
        name: 'Coupon',
        meta: {
          requiresAuth: true,
          title_cn: '优惠券',
          title_en: 'Coupon',
          title_tw: '優惠券',
        },
        component: () => import('../views/Coupon.vue'),
      },
    ]
  },
  {
    path: '/guide',
    name: 'Guide',
    meta: {
      requiresAuth: false,
      title_cn: '使用帮助',
      title_en: 'User Manual',
      title_tw: '使用幫助',
    },
    component: () => import('../views/Guide.vue'),
  },
  {
    path: '/invite',
    name: 'Invite',
    meta: {
      requiresAuth: true,
      title_cn: '邀请有礼',
      title_en: 'Referral Rewards',
      title_tw: '邀請有禮',
    },
    component: () => import('../views/Invite.vue'),
  },
  {
    path: '/inviterules',
    name: 'InviteRules',
    meta: {
      requiresAuth: false,
      title_cn: '“邀请有礼”活动规则',
      title_en: 'Referral Reward - Event Rules',
      title_tw: '“邀請有禮”活動規則',
    },
    component: () => import('../views/InviteRules.vue'),
  },
  {
    path: '/limitedactivity',
    name: 'LimitedActivity',
    meta: {
      requiresAuth: false,
      title_cn: '限时有礼',
      title_en: 'Limited time activity',
      title_tw: '限時有禮',
    },
    component: () => import('../views/LimitedActivity.vue'),
  },
  {
    path: '/activityrules',
    name: 'ActivityRules',
    meta: {
      requiresAuth: false,
      title_cn: '活动规则',
      title_en: 'Activity Rules',
      title_tw: '活動規則',
    },
    component: () => import('../views/ActivityRules.vue'),
  },
  {
    path: '/details',
    name: 'Details',
    meta: {
      requiresAuth: false,
      title_cn: '商品详情',
      title_en: 'Product details',
      title_tw: '商品詳情',
    },
    component: () => import('../views/Details.vue')
  },
  {
    path: '/pay',
    name: 'Pay',
    meta: {
      requiresAuth: true,
      title_cn: '确认订单',
      title_en: 'Confirm',
      title_tw: '確認訂單',
    },
    component: () => import('../views/Pay.vue')
  },
  {
    path: '/result',
    name: 'Result',
    meta: {
      requiresAuth: false,
      title_cn: '',
      title_en: '',
      title_tw: '',
    },
    component: () => import('../views/ResultView.vue')
  },
  {
    path: '/stripe',
    name: 'Stripe',
    meta: {
      requiresAuth: true,
      title_cn: '确认订单',
      title_en: 'Confirm',
      title_tw: '確認訂單',
    },
    component: () => import('../views/Stripe.vue')
  },
  {
    path: '/instruction',
    name: 'Instruction',
    meta: {
      title_cn: '使用教程',
      title_en: 'Instruction guide',
      title_tw: '使用教程',
    },
    component: () => import('../views/Instruction.vue')
  },
  {
    path: '/esimchange',
    name: 'EsimChange',
    meta: {
      title_cn: '多个eSIM套餐如何切换使用？',
      title_en: 'How do I switch between eSIMs?',
      title_tw: '多個eSIM套餐如何切換使用？',
    },
    component: () => import('../views/EsimChange.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: {
      title_cn: 'TraveleSIM 隐私政策',
      title_en: 'TraveleSIM Privacy Policy',
      title_tw: 'TraveleSIM 隱私政策',
    },
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/treaty',
    name: 'Treaty',
    meta: {
      title_cn: '用户协议',
      title_en: 'User Agreement',
      title_tw: '用戶協議',
    },
    component: () => import('../views/Treaty.vue')
  },
  {
    path: '/topuprules',
    name: 'TopupRules',
    meta: {
      title_cn: 'TraveleSIM充值服务协议',
      title_en: 'TraveleSIM Payment Services Agreement',
      title_tw: 'TraveleSIM充值服務協議',
    },
    component: () => import('../views/TopupRules.vue')
  },
  {
    path: '/devicesimage',
    name: 'DeviceImage',
    meta: {
      title_cn: 'eSIM 支持设备',
      title_en: 'eSIM Available Devices',
      title_tw: 'eSIM 支持設備',
    },
    component: () => import('../views/DeviceImage.vue')
  },
  {
    path: '/download',
    name: 'Download',
    meta: {
      title_cn: '下载TraveleSIM APP',
      title_en: 'Download TraveleSIM APP',
      title_tw: '下載TraveleSIM APP',
    },
    component: () => import('../views/Download.vue')
  },
  {
    path: '/qrcode',
    name: 'QRCode',
    meta: {
      title_cn: 'eSIM QRCode',
      title_en: 'eSIM QRCode',
      title_tw: 'eSIM QRCode',
    },
    component: () => import('../views/QRCode.vue')
  },
  {
    path: '/enquiry/iccid',
    name: 'EnquiryIccid',
    meta: {
      title_cn: 'DATAPLAN ENQUIRY',
      title_en: 'DATAPLAN ENQUIRY',
      title_tw: 'DATAPLAN ENQUIRY',
    },
    component: () => import('../views/enquiry/iccid.vue')
  },
  {
    path: '/enquiry/order',
    name: 'EnquiryOrder',
    meta: {
      title_cn: 'DATAPLAN ENQUIRY',
      title_en: 'DATAPLAN ENQUIRY',
      title_tw: 'DATAPLAN ENQUIRY',
    },
    component: () => import('../views/enquiry/order.vue')
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title_cn: '404',
      title_en: '404',
      title_tw: '404',
    },
    component: () => import('../views/NotFoundView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
export default router
