import {GetConfigAPI} from '../../api/api'
//公共模块
const INITIAL_CONFIG = {
  currency:'',
  keywords:[],
  roll:[],
  customer_tips:{}
};
const state = {
  config: INITIAL_CONFIG,
};

const mutations = {
  SET_CONFIG: (state, config) => {
    state.config = config;
  },
};
const actions = {
  GetConfig({ dispatch,commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {data:{currency,keywords,roll,customer_tips}} = await GetConfigAPI();
        commit("SET_CONFIG", {roll,currency,keywords,customer_tips});
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },


};

const getters = {
  currency: state => {
    return state.config.currency || '';
  },
  customer: state => {
    return state.config.customer_tips || {};
  },
};

export default {
  namespaced: true, //开启命名空间，用来映射子模块
  state,
  mutations, //如果开启了命名空间，就直接挂载到子模块上了，默认是挂载到全局的
  actions,
  getters,
};
