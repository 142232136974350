import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import config from './modules/config'
import createPersistedstate  from 'vuex-persistedstate'

Vue.use(Vuex)
 
export default new Vuex.Store({
  state: {
    isApp:false, // 是否是app端
    redirect:false, // 登录成功后跳转的页面
    loginModalVisible: false, // 登录框是否显示
    navBarHeight:81,// 导航栏高度
  },
  getters: {
  },
  mutations: {
    LOGIN_MODAL_VISIBLE: (state, visible) => {
      state.loginModalVisible = visible;
    },
    SET_IS_APP(state, isApp) {
      state.isApp = isApp;
    },
    SET_NAVBAR_HEIGHT(state, height) {
      state.navBarHeight = height-1;
    },
    SET_REDIRECT(state, redirect) {
      // 过滤掉token和authorization字段
      const { name,query:{ token, authorization, ...filtered} } = redirect;
      state.redirect = {name,query:filtered};
    },
    CLEAR_REDIRECT(state) {
      state.redirect = false;
    }
  },
  actions: {
  },
  modules: {
    user,
    config
  },
  plugins:[
    createPersistedstate({
      key:'user',// 存数据的key名   自定义的  要有语义化
      paths: ['user'] // 要把那些模块加入缓存
    }),
  ]
})

