<template>
  <div class="coupon">
    <van-overlay :show="visible" :lock-scroll="false" z-index="1000">
      <div class="wrapper" @click.stop>
        <div class="inner" >
          <div class="header" :style="bgImg">
            <van-icon class="close" @click="handleClose" name="cross" color="#999" size="20"/>
          </div>
          <div class="contents">
            <div class="scrolling-wrapper">
              <div class="item" v-for="item in coupons" :key="item.id">
                <div class="discount">
                  <div class="ellipsis text" style="color:#C30C2D;text-align: center;">
                    {{item.discount}}
                  </div>
                  <!-- <div class="ellipsis tips">{{item.limit_price}}</div> -->
                </div>
                <div class="describe">
                  <div class="ellipsis text" :title="item.name">{{item.name}}</div>
                  <!-- <div class="ellipsis tips">{{item.start_time}} - {{item.end_time}}</div> -->
                </div>
              </div>
            </div>
            <div class="footer">
              <van-button class="use" @click="handleUse" round color="linear-gradient(180deg, #FBE760 0%, #F9BD4F 100%)" size="large">{{$t('coupon.use')}}</van-button>
              <div class="check"  @click="handleUse">{{$t('coupon.check')}}</div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mapMutations } from 'vuex';
  export default{
    name:'coupon-dialogs',
    props:{
      visible:{
        type:Boolean,
        default:false
      },
      coupons:{
        type:Array,
        default:[]
      }
    },
    data() {
      return {
        en:{backgroundImage:`url(${require("@/assets/coupons_bg_en.png")})`},
        cn:{backgroundImage:`url(${require("@/assets/coupons_bg_cn.png")})`},
        tw:{backgroundImage:`url(${require("@/assets/coupons_bg_tw.png")})`},
        bgImg:'',
      }
    },
    computed: {
    ...mapGetters('user', ['isLogin']),
    },
    mounted() {
      const lang = this.$i18n.locale
      this.bgImg = lang == 'en' ? this.en : lang == 'zh-tw' ? this.tw : this.cn
    },
    methods:{
      ...mapMutations(['LOGIN_MODAL_VISIBLE']),
      handleUse(){
        this.handleClose()
        if(!this.isLogin) this.LOGIN_MODAL_VISIBLE(true)
      },
      handleClose(){
        this.$emit('close')
      },
    }
  }
</script>
<style lang="scss" scoped>
  .coupon{
    .wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      .inner{
        width: 320px;
        user-select: none;
        .header{
          width: 100%;
          height: 135px;
          background-size: 100% auto;
          background-repeat: no-repeat;
          display: flex;
          justify-content: flex-end;
          .close{
            cursor: pointer;
          }
        }
        .contents{
          padding-top: 10px;
          border-radius:  0 0 20px 20px;
          background: linear-gradient(180deg, #F7633C 0%, #EC432F 100%);
          .scrolling-wrapper {
            height: 230px;
            padding: 0 20px;
            margin-bottom: 20px;
            overflow-y: auto;
            &::-webkit-scrollbar{
              width: 0;
            }
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
            .item {
              margin: 10px 0;
              min-height: 100px;
              background-color: #fff;
              border-radius: 5px;
              // mask: radial-gradient(circle at 40% 0, transparent 0%, transparent 10px, #fff 10px) -10px;
              background: radial-gradient(circle at 10px, transparent 10px, #fff 0); 
              background-position: 90px -50px;
              display: flex;
              padding: 10px 0;
              .discount,.describe{
                display: flex;
                justify-content: center;
                flex-direction: column;
              }
              .text{
                font-size: 16px;
                font-weight: bold;
              }
              .tips{
                color:#5D5D5D;
                font-size: 12px;
                font-weight: normal;
                text-align: center;
              }
              .discount{
                width: 100px;
                padding: 0 10px;
                border-right: 2px dashed #FDA99B;
              }
              .describe{
                flex: 1;
                padding: 0 10px;

              }
              .ellipsis{
                width: 100%;
                word-break: keep-all;
                text-overflow: ellipsis;
                display:-webkit-box;
                -webkit-box-orient:vertical;
                -webkit-line-clamp:2;//控制行数
                overflow: hidden;
              }
            }
          }
          .footer{
            width: 100%;
            height: 110px;
            padding: 0 44px;
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-image: url("../assets/coupons_bg_qifen.png");
            .use{
              font-size: 18px;
              font-weight: bold;
              box-shadow: 0px 3px 0px 0px rgba(164,38,0,0.35), inset 2px 2px 4px 0px rgba(255,255,255,0.35), inset -1px -2px 4px 0px #E85D32;
              /deep/ .van-button__text{
                color: #C30C2D;
              }
            }
            .check{
              margin-top: 10px;
              color: #222222;
              font-size: 14px;
              text-align: center;
              text-decoration:underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>