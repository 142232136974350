const GOOGLE_CLIENT_ID = '515900643279-6ijg1garaku70issgr7lnnegcrpf4act.apps.googleusercontent.com'
const APPLE_CLIENT_ID = 'com.TravelesimHK.web'
const APPLE_REDIRECT_URI = process.env.NODE_ENV === "development" ? 'https://esim.dev.triproaming.com' :'https://esim.triproaming.com'
export function loadGoogleSignInScript() {//加载谷歌登录脚本
  console.log('Loading Google Sign-In script...')
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = () => {
      console.log('Google Sign-In script loaded')
      resolve(true)
    };
    script.onerror = (error) => {
      console.log('Failed to load Google Sign-In script:', error);
      reject(false)
    };
    document.head.appendChild(script);
  })
}
export function initGoogleSignIn(callback){//初始化谷歌登录
  return new Promise((resolve, reject) => {
    try {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback
      });
      const dom = document.getElementById("g_id_signin");
      window.google.accounts.id.renderButton(dom, {
          type: "icon",
          shape: "pill",
          theme: "outline",
      });
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

export function loadAppleSignInScript() {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.onload = () => {
      console.log('Apple Sign-In script loaded')
      resolve(true)
    };
    script.onerror = (error) => {
      console.log('Failed to load Apple Sign-In script:', error);
      reject(false)
    };
    document.head.appendChild(script);
  })
}
export function initAppleSignIn(){//初始化苹果登录
  return new Promise((resolve, reject) => {
    try {
      window.AppleID.auth.init({
        clientId : APPLE_CLIENT_ID,
        scope : 'email',
        redirectURI : APPLE_REDIRECT_URI,
        state: "initial",
        usePopup : true
      });
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

export function decodeJwtResponse(token) {//解析token
  const strings = token.split(".");
  return JSON.parse(
    decodeURIComponent(
      window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/"))
    )
  );
}