import {UserInfoAPI,UserLoginAPI,UserLogoutAPI,UserThirdLoginAPI} from '../../api/api'
import MD5 from "md5";

//用户模块
const INITIAL_INFO = {
  id:'',
  have_pwd:0,
  nickname: '',
  email: '',
  mobile: '',
  now_money: 0, //余额
  recharge_switch: 0, //充值开关
  yue_pay_status: 0, //余额支付开关
  is_sharer:0, //是否为分享者
  share_link: '',//分销链接
  payment:[]//支付方式
};
const state = {
  token: '',
  info: INITIAL_INFO,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_INFO: (state, info) => {
    state.info = info;
  },
};
const actions = {
  // 登录
  PasswordLogin({ dispatch, commit }, { text, pwd }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { token }
        } = await UserLoginAPI({ text, pwd: MD5(pwd) })
        commit("SET_TOKEN", token);
        dispatch("GetInfo");
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  ThirdLogin({ dispatch, commit }, { platform, auth_id }){
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { token,isbind },
        } = await UserThirdLoginAPI({ platform, auth_id })
        if (isbind) return reject({isbind});
        commit("SET_TOKEN", token);
        dispatch("GetInfo");
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  AuthTokenLogin({ dispatch, commit }, token){
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_TOKEN", token);
        dispatch("GetInfo");
        resolve()
      } catch (error) {
        reject()
      }
    });
  },
  Logout({ dispatch }){
    return new Promise(async (resolve, reject) => {
      try {
        await UserLogoutAPI()
        dispatch("Reset");
        resolve();
      } catch (error) {
        // dispatch("Reset");
        reject(error);
      }
    });
  },
  Reset({ commit },isIntercept){
    commit("SET_TOKEN", '');
    commit("SET_INFO", INITIAL_INFO);
    if(isIntercept)commit("LOGIN_MODAL_VISIBLE",true,{root:true});
  },
  GetInfo({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: {
            id,
            have_pwd,
            nickname,
            email,
            mobile,
            now_money,
            recharge_switch,
            yue_pay_status,
            is_sharer,
            share_link,
            payment,
          },
        } = await UserInfoAPI()
        const info = {
          id,
          have_pwd,
          nickname,
          email,
          mobile,
          now_money,
          recharge_switch,
          yue_pay_status,
          is_sharer,
          share_link,
          payment
        };
        commit("SET_INFO", info);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
};

const getters = {
  isLogin: state => {
    return state.token !== '';
  },
  hasPwd: state => {
    return state.info.have_pwd === 1;
  },
  isSharer: state => {
    return state.info.is_sharer === 1; 
  },
  isWallet: state => {
    return state.info.recharge_switch === 1; 
  },
  isWalletPay: state => {
    return state.info.yue_pay_status === 1; 
  },
};

export default {
  namespaced: true, //开启命名空间，用来映射子模块
  state,
  mutations, //如果开启了命名空间，就直接挂载到子模块上了，默认是挂载到全局的
  actions,
  getters,
};
