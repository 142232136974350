<template>
  <div id="nav-wrapper" ref="navbar">
    <div class="notice" @click="handleBannerClick" v-if="noticeVisible" :class="{'has-img':noticeImage}" :style="{backgroundImage:`url(${noticeImage})`}">
      <div class="container">
        <van-icon v-if="noticeImage" class="clear-icon" name="clear" @click.stop="onCloseHandler" />
        <!-- <van-notice-bar v-if="!noticeImage" @close="onCloseHandler" mode="closeable" wrapable left-icon="volume" color="#222222" background="transparent" :scrollable="false">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
          >
            <van-swipe-item v-for="(item,index) in notices" :key="index">
              {{ item.title }}
            </van-swipe-item>
          </van-swipe>
        </van-notice-bar> -->
        <van-notice-bar
          v-if="!noticeImage"
          mode="closeable" 
          color="#222222" 
          left-icon="volume" 
          background="transparent"
          @close.stop="onCloseHandler" 
        >
        <pre>{{noticeText}}</pre>
      </van-notice-bar>
      </div>
    </div>
    <div class="nav-container flex-center-between container">
      <div class="flex-center-start">
        <div class="logo">
          <img src="../assets/header_logo.png" @click="handleBackHome">
        </div>
        <div class="slogan" @click="handleBackHome">{{$t('common.slogan')}}</div>
        <div class="global-search-wrapper pc-search">
          <a-auto-complete
            class="global-search"
            size="large"
            v-model="search.keyword"
            :defaultActiveFirstOption="false"
            :placeholder="$t('route.search')"
            option-label-prop="title"
            :getPopupContainer="getPopupContainer"
            @select="onSearchSelectHandler"
            @search="handleSearch"
          >
            <template slot="dataSource">
              <a-select-option class="test" v-for="item in dataSource" :key="item.name" :product_id="item.product_id" :title="item.name">
                <div class="flex-center-start">
                  <div class="global-search-image">
                    <img :src="item.image" alt="">
                  </div>
                  <div class="text-overflow-example"> 
                  {{ item.name }}
                  </div>
                </div>
              </a-select-option>
            </template>
            <a-input class="search-input" @keyup.enter="onSearchHandler">
              <a-button
                @click="onSearchHandler"
                slot="suffix"
                class="search-btn flex"
                size="large"
                type="primary"
              >
                <a-icon type="search"/>
              </a-button>
            </a-input>
          </a-auto-complete>
        </div>
      </div>
      <div class="navbar-wrapper flex-center-start">
        <div class="nav-list">
          <router-link class="link-pc" to="/">{{ $t("route.home") }}</router-link>
          <router-link class="link-pc" v-if="isLogin" to="/mine/user">{{ $t("route.mine") }}</router-link>
          <span class="link-pc" v-else @click="LOGIN_MODAL_VISIBLE(true)">{{ $t("route.login") }}</span>
          <span class="link-pc" @click="onSelectHandler({url:'ApplicableDeviceVisible'})">{{ $t("route.device") }}</span>
          <span class="link-pc" @click="onSelectHandler({url:'ActivateStepVisible'})">{{$t('route.guide')}}</span>
          <router-link class="link-pc" to="/guide">{{ $t("route.faq") }}</router-link>
          <router-link to="/guide" class="flex link-app">
            <a-icon type="question-circle" style="font-size: 18px;"/>
          </router-link>
          <van-popover v-if="isLogin"
            class="app-nav-popover"
            placement="bottom-end" 
            v-model="AppPopover" 
            trigger="click"
            :actions="AppLinks" 
            @select="onSelectHandler">
            <template #reference>
              <div class=" flex">
                <a class="link-app">
                  <a-icon type="align-center" style="font-size: 18px;"/>
                </a>
              </div>
            </template>
          </van-popover>
          <span class="flex link-app" v-else @click="LOGIN_MODAL_VISIBLE(true)">
            <!-- <a-icon type="login"/> -->
            {{ $t("route.login") }}
          </span>
        </div>
        <div class="nav-locales">
          <span @click="onLangClick('zh-cn')" :class="{ 'locales-active': $i18n.locale == 'zh-cn' }">简</span>
          <a-divider type="vertical"/>
          <span @click="onLangClick('zh-tw')" :class="{ 'locales-active': $i18n.locale == 'zh-tw' }">繁</span>
          <a-divider type="vertical"/>
          <span @click="onLangClick('en')" :class="{ 'locales-active': $i18n.locale == 'en' }">EN</span>
        </div>
      </div>
    </div>
    <div class="global-search-wrapper app-search container">
      <a-auto-complete
        class="global-search"
        size="large"
        v-model="search.keyword"
        :defaultActiveFirstOption="false"
        :placeholder="$t('route.search')"
        option-label-prop="title"
        :getPopupContainer="getPopupContainer"
        @select="onSearchSelectHandler"
        @search="handleSearch"
      >
        <template slot="dataSource">
          <a-select-option v-for="item in dataSource" :key="item.name" :product_id="item.product_id"  :title="item.name">
            <div class="flex-center-start">
              <div class="global-search-image flex">
                <img :src="item.image" alt="">
              </div>
              <div class="text-overflow-example"> 
              {{ item.name }}
              </div>
            </div>
          </a-select-option>
        </template>
        <a-input class="search-input" @keyup.enter="onSearchHandler">
          <a-button
            @click="onSearchHandler"
            slot="suffix"
            style="margin-right: -12px"
            class="search-btn flex"
            size="large"
            type="primary"
          >
            <a-icon type="search"/>
          </a-button>
        </a-input>
      </a-auto-complete>
    </div>
  </div>
</template>
<script>
import { mapState,mapGetters,mapActions,mapMutations } from 'vuex';
import { AutoComplete } from 'ant-design-vue'
import { ApplicableDeviceImagePreview,ActivateStepImagePreview } from '../utils/imagePreview'
export default {
  components:{
    [AutoComplete.name]:AutoComplete,
  },
  data() {
    return {
      notices:[],
      noticeVisible: false,
      noticeImage:'',
      noticeText:'',
      search:{
        keyword:'',
        product_id:'',
      },
      dataSource:[],
      keywordSource:[],
      AppPopover: false,
      commonLinks: [
        { text: this.$t('route.user'), url: 'User' },
        { text: this.$t('route.flow'), url: 'Esims' }, 
        { text: this.$t('route.order'), url: 'Order' },
        { text: this.$t('route.coupon'), url: 'Coupon' }, 
        { text: this.$t('route.invite'), url: 'Invite' }, 
        { text: this.$t('route.device'), url: 'ApplicableDeviceVisible' }, 
        { text: this.$t('route.guide'), url: 'ActivateStepVisible' }, 
        { text: this.$t("route.logout"), url: 'Logout' }
      ],
    }
  },
  computed: {
    ...mapState('config',['config']),
    ...mapState("user", ["token"]),
    ...mapGetters('user',['isLogin','isWallet','isSharer']),
    AppLinks() {
      const links = [...this.commonLinks];
      if (this.isSharer) {
        links.splice(0, 0, { text: this.$t('route.share'), url: 'Share' });
      }
      if (this.isWallet) {
        links.splice(1, 0, { text: this.$t('route.wallet'), url: 'Wallet' });
      }
      if (this.$route.name!=='Home') {
        links.splice(0, 0, { text: this.$t("route.home"), url: 'Home' });
      }
      return links;
    },
  },
  mounted(){
    this.init()
  },
  methods:{
    ...mapActions('user',['Logout']),
    ...mapActions('config', ['GetConfig']),
    ...mapMutations(['LOGIN_MODAL_VISIBLE','SET_NAVBAR_HEIGHT']),
    handleBannerClick() {
      let { jump_type, path, extra:{target} } = this.notices[0];
      jump_type == 1 && this.$router.push({ path });
      if (jump_type == 2) {
        const token = this.token || "";
        if (path.indexOf("?") > -1) {
          path = path + "&lang=" + this.$i18n.locale;
        } else {
          path = path + "?lang=" + this.$i18n.locale;
        }
        if (token) path = path + "&token=" + encodeURIComponent(token);
        const open_type = target === '_blank' ? '_blank' : '_self';
        window.open(path, open_type);
        window._hmt.push(["_trackEvent", "button", "click", `Banner跳转-${path}`]);
      }
    },
    getPopupContainer(triggerNode ){
      return triggerNode.parentNode || document.body
    },
    onSearchSelectHandler(keyword, option) {
      const { data:{attrs:{product_id}} } = option
      this.search = { keyword, product_id }
      this.$router.push({ name: 'Search', query: this.search});
    },
    onSearchHandler(){
      const { keyword,product_id } = this.search
      if(!keyword.replace(/\s/g, '')) return
      const query = {
        keyword,
        ...(product_id&&{product_id})
      }
      this.$router.push({ name: 'Search', query });
    },
    handleSearch(keyword) {
      this.search.product_id = ''
      this.dataSource = keyword ? this.filterKeyword(keyword) : [];
    },
    filterKeyword(searchText) {
      const lowerCaseSearchText = searchText.toLowerCase();
      const filteredData = this.keywordSource.filter(item => {
        const lowerCaseKeyword = item.keyword?.toLowerCase();
        const lowerCaseName = item.name?.toLowerCase();
        return lowerCaseName.includes(lowerCaseSearchText) || lowerCaseKeyword.includes(lowerCaseSearchText)
      });
      return filteredData;
    },
    deduplicateKeywords(keywords){
      return keywords.reduce((unique, item) => {
        if (!unique.some(i => i.name.trim() === item.name.trim())) {
          unique.push(item);
        }
        return unique;
      }, []);
    },
    async init(){
      try {
        await this.GetConfig()
        const { keywords,roll } = this.config
        this.keywordSource = this.deduplicateKeywords(keywords)
        if(!roll.length) return
        this.noticeVisible = true
        this.notices = roll
        const [{image,image_min,title}] = roll;
        // console.log(window.innerWidth)
        this.noticeImage = window.innerWidth >= 768 ? image : image_min;
        this.noticeText = title;
        this.resetHeightHandler()
      } catch (error) {
        console.error('公共接口报错')
      }
    },
    onCloseHandler(){
      this.noticeVisible = false
      this.resetHeightHandler()
    },
    resetHeightHandler(){
      this.$nextTick(() => {
        const { offsetHeight } = this.$refs.navbar
        this.SET_NAVBAR_HEIGHT(offsetHeight)
      })
    },
    async onLogoutHandler(){
      await this.$dialog.confirm({
        title: this.$t('login.logout_title'),
        message: this.$t('login.logout_text'),
        confirmButtonText: this.$t('login.logout_ok'),
        cancelButtonText: this.$t('common.cancel'),
      })
      await this.Logout()
      const { requiresAuth } = this.$route.meta
      if(requiresAuth) this.$router.push({ name: 'Home' });
    },
    onSelectHandler(name) {
      const { url } = name
      if (url === 'ApplicableDeviceVisible') return ApplicableDeviceImagePreview()
      if (url === 'ActivateStepVisible') {
        const locale = this.$i18n.locale
        if(document.body.clientWidth <= 768){
          this.$router.push({ name: 'Instruction', query: {locale}});
          return
        }
        const lang = locale == 'zh-cn' ? 'zh-cn' : locale == 'zh-tw' ? 'hk-tw' : 'en'
        ActivateStepImagePreview(lang)
        return 
      }
      if (url === 'Logout') return this.onLogoutHandler()
      this.$router.push({ name:url });
    },
    onLangClick(lang){
      if(lang === this.$i18n.locale) return
      const langMap = { 'zh-cn': 'zh-cn', 'en': 'en', 'zh-tw': 'zh-tw', };
      if (this.$route.query.lang) { 
        let query = Object.assign({}, this.$route.query);
        query.lang = lang;
        this.$router.push({ query }); 
      }
      localStorage.setItem('esim_lang', langMap[lang]);
      this.$i18n.locale = localStorage.getItem('esim_lang'); 
      location.reload();
    },
    handleBackHome(){
      this.$router.push({ name: 'Home' });
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .global-search-wrapper {
  .global-search {
    width: 100%;
  }
  .global-search-image{
    width: 42px;
    margin-right: 10px;
    img{
      border-radius:6px;
    }
  }
  .search-input{
    transition: all 0.5s ease;
    .ant-input{
      padding-left: 16px;
      padding-right: 66px;
      border-radius: 20px;
      border-color: #999999;
      &:focus{
        box-shadow: none;
        border-color: $color;
      }
    }
    .ant-input-suffix{
      @media(max-width:992px){
        right: 22px;
      }
    }
  }
  .search-btn{
    padding: 0 10px;
    width: 50px;
    height: 30px;
    border-radius: 18px;
    border-color: $color;
    background-color: $color;
    .anticon-search{
      font-size: 16px;
    }
  }
}
/deep/ .pc-search{
  display: block;
  margin-left: 12px;
  .search-input{
    @mixin search-input-active {
      width: 320px;
      white-space: initial;
      overflow: initial;
      text-overflow: initial;
      border-color: $color;
    }
    .ant-input{
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover{
        @include search-input-active
      }
      &:focus{
        @include search-input-active
      }
    }
    &:hover{
      .ant-input{
        @include search-input-active
      }
    }
  }
}
.slogan{
  cursor: pointer;
  display: none;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  padding-left: 8px;
  border-left: 2px solid #999999;
  @media(min-width:1400px) {
    display: block;
  }
}
#nav-wrapper {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  flex-direction: column;
  user-select: none;
  font-size: 14px;
  min-height: 80px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 1px solid #F2F3F6;
  .notice{
    cursor: pointer;
    width: 100%;
    transition: all 0.5s ease;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: rgba(246, 242, 252,0.5);
    /deep/ .van-notice-bar{
      padding: 0;
      .notice-swipe {
        height: 40px;
        line-height: 40px;
      }
      .van-icon{
        color: $color;
      }
      .van-swipe-item{
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:1;//控制行数
        overflow:hidden;
      }
      pre{
        margin: 0;
        font-size: 16px;
        font-family: "SourceHanSans";
      }
    }
  }
  .has-img{
    height: 80px;
    @media(max-width:1600px){
      height: 68px;
    }
    .container{
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .clear-icon{
        cursor: pointer;
        color: rgba(255, 255, 255,0.8);
        font-size: 20px;
      }
    }
  }
  .nav-container{
    padding-top: 6px;
    padding-bottom: 6px;
    min-height: 80px;
  }
  .logo {
    width: 110px;
    cursor: pointer;
  }
  .app-search{
    display: none;
  }
  .nav-list {
    .link-app {
      display: none;
    }
  }
  .nav-locales {
    text-align: center;
    span {
      color: #999;
      user-select: none;
      cursor: pointer;
    }
    .locales-active {
      color: #333;
      font-weight: 600;
    }
  }
  .link-pc {
    cursor: pointer;
    color: #999;
    position: relative;
    margin-right: 14px;
    transition: all .3s cubic-bezier(0.4,0.8,0.74,1);
    &::after {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 0);
      content: '';
      width: 0;
      height: 2px;
      border-radius: 12px;
      background-color: $color;
      transition: all .4s cubic-bezier(0.4,0.8,0.74,1);
    }
    &:hover{
      color: $color;
      &::after{
        width: 20px;
      }
    }
    &.router-link-exact-active {
      color: $color;
      font-weight: 600;
      &::after {
        width: 30px;
      }
    }
  }
}
@media(max-width:992px) {
  #nav-wrapper {
    height: auto;
    justify-content: space-between;
    .notice{
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .has-img{
      height: 47px;
    }
    .nav-container{
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 0px;
      min-height: initial;
    }
    .logo{
      @media(max-width:320px) {
        width: 110px;
      }
    }
    .navbar-wrapper {
      flex-direction: row-reverse;
    }
    .pc-search{
      display: none;
    }
    .app-search{
      display: block;
      padding: 10px 16px;
    }
    .nav-list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .link-pc {
        display: none;
      }
      .link-app{
        display: flex;
        color: #666;
        font-size: 14px;
        margin-left: 14px;
      }
    }
  }
}
</style>