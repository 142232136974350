import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './locales'
import Vant from 'vant';
import '@vant/touch-emulator';
import 'vant/lib/index.css';
import "swiper/css/swiper.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/styles/iconfont/iconfont.css';
import { Lazyload } from 'vant';
import Vconsole from "vconsole";
import { Modal,FormModel,Input,Row,Col,Icon,Button,Table,Space,Spin,Select,Empty,Divider } from 'ant-design-vue'

//开发环境使用，生产环境自动取消
if (process.env.NODE_ENV == "development") {
  new Vconsole();
}
Vue.use(Modal)
Vue.use(Table)
Vue.use(Select);
Vue.use(Input)
Vue.use(FormModel);
Vue.component(Button.name, Button);
Vue.component(Space.name, Space);
Vue.component(Spin.name, Spin);
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);
Vue.component(Icon.name, Icon);
Vue.component(Empty.name, Empty);
Vue.component(Divider.name, Divider);
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.config.productionTip = false
Vue.use(Vant);

function isAPP(){
	const system = navigator.userAgent.toLowerCase();
	if (system.includes('travelesim') && system.includes('ios')) {
			return 'ios';
	} else if (system.includes('travelesim') && system.includes('android')) {
			return 'android';
	} else {
			return false;
	}
}
(function() {
  const system = isAPP();
  store.commit('SET_IS_APP', system,{root:true});
})();
const EXCLUDE_ROUTE_NAME  = [ 'QRCode','EnquiryOrder','EnquiryIccid' ]
router.afterEach((to, from) => {
	const { meta:{title_en,title_tw,title_cn} } = to
	document.title = i18n.locale == 'zh-cn' ? title_cn : i18n.locale == 'zh-tw' ? title_tw : title_en
})
router.beforeEach((to, from, next) => {
	const { name,meta:{requiresAuth},query:{lang,token,authorization,invitecode} } = to
	if(lang) {
		i18n.locale = lang == 'zh-cn' ? 'zh-cn' : lang == 'zh-tw' ? 'zh-tw' : 'en'
		localStorage.setItem('esim_lang', i18n.locale)
	}
	if(EXCLUDE_ROUTE_NAME.includes(name)) store.commit('SET_IS_APP', true ,{root:true});
	if(store.state['isApp']&&!requiresAuth) return next();

	if (token || authorization) {
		const authToken = authorization || token;
		store.dispatch('user/AuthTokenLogin', decodeURIComponent(authToken))
	}
	
	if (requiresAuth && !store.getters['user/isLogin']) {
		if (store.state['isApp']) return next();
		store.dispatch('user/Reset',true)
		const redirect = { name,query:to.query}
		store.commit('SET_REDIRECT',redirect,{root:true})
		if(invitecode) sessionStorage.setItem("invitecode", invitecode);
		if(from.name!=='Home') next('/');
	} else {
		next();
	}
})

const app = new Vue({
    i18n,
    store,
    router,
    render: h => h(App)
}).$mount('#app')

export default app
