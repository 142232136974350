export default [
  {
    "label_en": "China +86",
    "label_zh-cn": "中国大陆 +86",
    "label_zh-tw": "中國大陸 +86",
    "pinyin": "zhongguodalu",
    "value": "86",
    "key": 0
  },
  {
    "label_en": "Hong Kong region +852",
    "label_zh-cn": "香港地区 +852",
    "label_zh-tw": "香港地區 +852",
    "pinyin": "xianggang",
    "value": "852",
    "key": 1
  },
  {
    "label_en": "Macau region +853",
    "label_zh-cn": "澳门地区 +853",
    "label_zh-tw": "澳門地區 +853",
    "pinyin": "aomen",
    "value": "853",
    "key": 2
  },
  {
    "label_en": "Taiwan region +886",
    "label_zh-cn": "台湾地区 +886",
    "label_zh-tw": "台灣地區 +886",
    "pinyin": "taiwan",
    "value": "886",
    "key": 3
  },
  {
    "label_en": "United States +1",
    "label_zh-cn": "美国 +1",
    "label_zh-tw": "美國 +1",
    "pinyin": "meiguo",
    "value": "1",
    "key": 4
  },
  {
    "label_en": "Japan +81",
    "label_zh-cn": "日本 +81",
    "label_zh-tw": "日本 +81",
    "pinyin": "riben",
    "value": "81",
    "key": 5
  },
  {
    "label_en": "South Korea +82",
    "label_zh-cn": "韩国 +82",
    "label_zh-tw": "韓國 +82",
    "pinyin": "hanguo",
    "value": "82",
    "key": 6
  },
  {
    "label_en": "Antarctica +64672",
    "label_zh-cn": "南极洲 +64672",
    "label_zh-tw": "南極洲 +64672",
    "pinyin": "nanjizhou",
    "value": "64672",
    "key": 7
  },
  {
    "label_en": "Jamaica +1876",
    "label_zh-cn": "牙买加 +1876",
    "label_zh-tw": "牙買加 +1876",
    "pinyin": "yamaijia",
    "value": "1876",
    "key": 8
  },
  {
    "label_en": "Barbados +1246",
    "label_zh-cn": "巴巴多斯 +1246",
    "label_zh-tw": "巴巴多斯 +1246",
    "pinyin": "babaduosi",
    "value": "1246",
    "key": 9
  },
  {
    "label_en": "Saint Lucia +1758",
    "label_zh-cn": "圣卢西亚 +1758",
    "label_zh-tw": "聖盧西亞 +1758",
    "pinyin": "shengluxiya",
    "value": "1758",
    "key": 10
  },
  {
    "label_en": "Anguilla +1264",
    "label_zh-cn": "安圭拉岛 +1264",
    "label_zh-tw": "安圭拉島 +1264",
    "pinyin": "anguiladao",
    "value": "1264",
    "key": 11
  },
  {
    "label_en": "Bahamas +1242",
    "label_zh-cn": "巴哈马 +1242",
    "label_zh-tw": "巴哈馬 +1242",
    "pinyin": "bahama",
    "value": "1242",
    "key": 12
  },
  {
    "label_en": "Puerto Rico +1809",
    "label_zh-cn": "波多黎各 +1809",
    "label_zh-tw": "波多黎各 +1809",
    "pinyin": "boduolige",
    "value": "1809",
    "key": 13
  },
  {
    "label_en": "The Virgin Islands +1809",
    "label_zh-cn": "维尔京群岛 +1809",
    "label_zh-tw": "維爾京群島 +1809",
    "pinyin": "weierjingqundao",
    "value": "1809",
    "key": 14
  },
  {
    "label_en": "Wake Island +1808",
    "label_zh-cn": "威克岛 +1808",
    "label_zh-tw": "威克島 +1808",
    "pinyin": "weikedao",
    "value": "1808",
    "key": 15
  },
  {
    "label_en": "Midway island +1808",
    "label_zh-cn": "中途岛 +1808",
    "label_zh-tw": "中途島 +1808",
    "pinyin": "zhongtudao",
    "value": "1808",
    "key": 16
  },
  {
    "label_en": "Greenland +299",
    "label_zh-cn": "格陵兰岛 +299",
    "label_zh-tw": "格陵蘭島 +299",
    "pinyin": "gelinglandao",
    "value": "299",
    "key": 17
  },
  {
    "label_en": "Alaska +1907",
    "label_zh-cn": "阿拉斯加 +1907",
    "label_zh-tw": "阿拉斯加 +1907",
    "pinyin": "alasijia",
    "value": "1907",
    "key": 18
  },
  {
    "label_en": "Hawaii +1808",
    "label_zh-cn": "夏威夷 +1808",
    "label_zh-tw": "夏威夷 +1808",
    "pinyin": "xiaweiyi",
    "value": "1808",
    "key": 19
  },
  {
    "label_en": "Canada +1",
    "label_zh-cn": "加拿大 +1",
    "label_zh-tw": "加拿大 +1",
    "pinyin": "jianada",
    "value": "1",
    "key": 20
  },
  {
    "label_en": "Christmas island +619164",
    "label_zh-cn": "圣诞岛 +619164",
    "label_zh-tw": "聖誕島 +619164",
    "pinyin": "shengdandao",
    "value": "619164",
    "key": 21
  },
  {
    "label_en": "Norfolk Island +6723",
    "label_zh-cn": "诺福克岛 +6723",
    "label_zh-tw": "諾福克島 +6723",
    "pinyin": "nuofukedao",
    "value": "6723",
    "key": 22
  },
  {
    "label_en": "Cocos Island +619162",
    "label_zh-cn": "科科斯岛 +619162",
    "label_zh-tw": "科科斯島 +619162",
    "pinyin": "kekesidao",
    "value": "619162",
    "key": 23
  },
  {
    "label_en": "Tuvalu +688",
    "label_zh-cn": "图瓦卢 +688",
    "label_zh-tw": "圖瓦盧 +688",
    "pinyin": "tuwalu",
    "value": "688",
    "key": 24
  },
  {
    "label_en": "Kiribati +686",
    "label_zh-cn": "基里巴斯 +686",
    "label_zh-tw": "基裏巴斯 +686",
    "pinyin": "jilibasi",
    "value": "686",
    "key": 25
  },
  {
    "label_en": "Western Samoa +685",
    "label_zh-cn": "西萨摩亚 +685",
    "label_zh-tw": "西薩摩亞 +685",
    "pinyin": "xisamoya",
    "value": "685",
    "key": 26
  },
  {
    "label_en": "Eastern Samoa +684",
    "label_zh-cn": "东萨摩亚 +684",
    "label_zh-tw": "東薩摩亞 +684",
    "pinyin": "dongsamoya",
    "value": "684",
    "key": 27
  },
  {
    "label_en": "Niue +683",
    "label_zh-cn": "纽埃岛 +683",
    "label_zh-tw": "紐埃島 +683",
    "pinyin": "niuaidao",
    "value": "683",
    "key": 28
  },
  {
    "label_en": "Cork Islands +682",
    "label_zh-cn": "科克群岛 +682",
    "label_zh-tw": "科克群島 +682",
    "pinyin": "kekequndao",
    "value": "682",
    "key": 29
  },
  {
    "label_en": "Fiji +679",
    "label_zh-cn": "斐济 +679",
    "label_zh-tw": "斐濟 +679",
    "pinyin": "fiji",
    "value": "679",
    "key": 30
  },
  {
    "label_en": "Vanuatu +678",
    "label_zh-cn": "瓦努阿图 +678",
    "label_zh-tw": "瓦努阿圖 +678",
    "pinyin": "wanuatu",
    "value": "678",
    "key": 31
  },
  {
    "label_en": "Solomon Islands +677",
    "label_zh-cn": "所罗门群岛 +677",
    "label_zh-tw": "所羅門群島 +677",
    "pinyin": "suoluomenqundao",
    "value": "677",
    "key": 32
  },
  {
    "label_en": "Tonga +676",
    "label_zh-cn": "汤加 +676",
    "label_zh-tw": "湯加 +676",
    "pinyin": "tangjia",
    "value": "676",
    "key": 33
  },
  {
    "label_en": "Nauru +674",
    "label_zh-cn": "瑙鲁 +674",
    "label_zh-tw": "瑙魯 +674",
    "pinyin": "naolu",
    "value": "674",
    "key": 34
  },
  {
    "label_en": "Guam +1671",
    "label_zh-cn": "关岛 +1671",
    "label_zh-tw": "關島 +1671",
    "pinyin": "guandao",
    "value": "1671",
    "key": 35
  },
  {
    "label_en": "New Zealand +64",
    "label_zh-cn": "新西兰 +64",
    "label_zh-tw": "新西蘭 +64",
    "pinyin": "xinxilan",
    "value": "64",
    "key": 36
  },
  {
    "label_en": "Australia +61",
    "label_zh-cn": "澳大利亚 +61",
    "label_zh-tw": "澳大利亞 +61",
    "pinyin": "aodaliya",
    "value": "61",
    "key": 37
  },
  {
    "label_en": "Monaco +377",
    "label_zh-cn": "摩纳哥 +377",
    "label_zh-tw": "摩納哥 +377",
    "pinyin": "monage",
    "value": "377",
    "key": 38
  },
  {
    "label_en": "Faroe Islands +298",
    "label_zh-cn": "法罗群岛 +298",
    "label_zh-tw": "法羅群島 +298",
    "pinyin": "faluoqundao",
    "value": "298",
    "key": 39
  },
  {
    "label_en": "Aruba +297",
    "label_zh-cn": "阿鲁巴岛 +297",
    "label_zh-tw": "阿魯巴島 +297",
    "pinyin": "alubadao",
    "value": "297",
    "key": 40
  },
  {
    "label_en": "Eritrea +291",
    "label_zh-cn": "厄立特里亚 +291",
    "label_zh-tw": "厄立特裏亞 +291",
    "pinyin": "eliteliya",
    "value": "291",
    "key": 41
  },
  {
    "label_en": "St. Helena +290",
    "label_zh-cn": "圣赫勒拿 +290",
    "label_zh-tw": "聖赫勒拿 +290",
    "pinyin": "shenghelena",
    "value": "290",
    "key": 42
  },
  {
    "label_en": "Comoros +269",
    "label_zh-cn": "科摩罗 +269",
    "label_zh-tw": "科摩羅 +269",
    "pinyin": "kemoluo",
    "value": "269",
    "key": 43
  },
  {
    "label_en": "Swaziland +268",
    "label_zh-cn": "斯威士兰 +268",
    "label_zh-tw": "斯威士蘭 +268",
    "pinyin": "siweishilan",
    "value": "268",
    "key": 44
  },
  {
    "label_en": "Botswana +267",
    "label_zh-cn": "博茨瓦纳 +267",
    "label_zh-tw": "博茨瓦納 +267",
    "pinyin": "bociwana",
    "value": "267",
    "key": 45
  },
  {
    "label_en": "Lesotho +266",
    "label_zh-cn": "莱索托 +266",
    "label_zh-tw": "萊索托 +266",
    "pinyin": "laisuotuo",
    "value": "266",
    "key": 46
  },
  {
    "label_en": "Malawi +265",
    "label_zh-cn": "马拉维 +265",
    "label_zh-tw": "馬拉維 +265",
    "pinyin": "malawei",
    "value": "265",
    "key": 47
  },
  {
    "label_en": "Namibia +264",
    "label_zh-cn": "纳米比亚 +264",
    "label_zh-tw": "納米比亞 +264",
    "pinyin": "namibiya",
    "value": "264",
    "key": 48
  },
  {
    "label_en": "Zimbabwe +263",
    "label_zh-cn": "津巴布韦 +263",
    "label_zh-tw": "津巴布韋 +263",
    "pinyin": "jinbabuwei",
    "value": "263",
    "key": 49
  },
  {
    "label_en": "Réunion +262",
    "label_zh-cn": "留尼旺岛 +262",
    "label_zh-tw": "留尼旺島 +262",
    "pinyin": "liuniwangdao",
    "value": "262",
    "key": 50
  },
  {
    "label_en": "Madagascar +261",
    "label_zh-cn": "马达加斯加 +261",
    "label_zh-tw": "馬達加斯加 +261",
    "pinyin": "madajiasijia",
    "value": "261",
    "key": 51
  },
  {
    "label_en": "Zambia +260",
    "label_zh-cn": "赞比亚 +260",
    "label_zh-tw": "贊比亞 +260",
    "pinyin": "zanbiya",
    "value": "260",
    "key": 52
  },
  {
    "label_en": "Mozambique +258",
    "label_zh-cn": "莫桑比克 +258",
    "label_zh-tw": "莫桑比克 +258",
    "pinyin": "mosangbike",
    "value": "258",
    "key": 53
  },
  {
    "label_en": "Burundi +257",
    "label_zh-cn": "布隆迪 +257",
    "label_zh-tw": "布隆迪 +257",
    "pinyin": "bulongdi",
    "value": "257",
    "key": 54
  },
  {
    "label_en": "Uganda +256",
    "label_zh-cn": "乌干达 +256",
    "label_zh-tw": "烏幹達 +256",
    "pinyin": "wuganda",
    "value": "256",
    "key": 55
  },
  {
    "label_en": "Tanzania +255",
    "label_zh-cn": "坦桑尼亚 +255",
    "label_zh-tw": "坦桑尼亞 +255",
    "pinyin": "tansangniya",
    "value": "255",
    "key": 56
  },
  {
    "label_en": "Kenya +254",
    "label_zh-cn": "肯尼亚 +254",
    "label_zh-tw": "肯尼亞 +254",
    "pinyin": "kenniya",
    "value": "254",
    "key": 57
  },
  {
    "label_en": "Djibouti +253",
    "label_zh-cn": "吉布提 +253",
    "label_zh-tw": "吉布提 +253",
    "pinyin": "jibuti",
    "value": "253",
    "key": 58
  },
  {
    "label_en": "Somalia +252",
    "label_zh-cn": "索马里 +252",
    "label_zh-tw": "索馬裏 +252",
    "pinyin": "suomali",
    "value": "252",
    "key": 59
  },
  {
    "label_en": "Ethiopia +251",
    "label_zh-cn": "埃塞俄比亚 +251",
    "label_zh-tw": "埃塞俄比亞 +251",
    "pinyin": "aisaiebiya",
    "value": "251",
    "key": 60
  },
  {
    "label_en": "Rwanda +250",
    "label_zh-cn": "卢旺达 +250",
    "label_zh-tw": "盧旺達 +250",
    "pinyin": "luwangda",
    "value": "250",
    "key": 61
  },
  {
    "label_en": "Sudan +249",
    "label_zh-cn": "苏丹 +249",
    "label_zh-tw": "蘇丹 +249",
    "pinyin": "sudan",
    "value": "249",
    "key": 62
  },
  {
    "label_en": "Seychelles +248",
    "label_zh-cn": "塞舌尔 +248",
    "label_zh-tw": "塞舌爾 +248",
    "pinyin": "saisheer",
    "value": "248",
    "key": 63
  },
  {
    "label_en": "Ascension +247",
    "label_zh-cn": "阿森松 +247",
    "label_zh-tw": "阿森松 +247",
    "pinyin": "asensong",
    "value": "247",
    "key": 64
  },
  {
    "label_en": "Guinea-Bissau +245",
    "label_zh-cn": "几内亚比绍 +245",
    "label_zh-tw": "幾內亞比紹 +245",
    "pinyin": "jineiyabishao",
    "value": "245",
    "key": 65
  },
  {
    "label_en": "Angola +244",
    "label_zh-cn": "安哥拉 +244",
    "label_zh-tw": "安哥拉 +244",
    "pinyin": "angela",
    "value": "244",
    "key": 66
  },
  {
    "label_en": "Zaire +243",
    "label_zh-cn": "扎伊尔 +243",
    "label_zh-tw": "紮伊爾 +243",
    "pinyin": "zhayier",
    "value": "243",
    "key": 67
  },
  {
    "label_en": "Congo +242",
    "label_zh-cn": "刚果 +242",
    "label_zh-tw": "剛果 +242",
    "pinyin": "gangguo",
    "value": "242",
    "key": 68
  },
  {
    "label_en": "Gabon +241",
    "label_zh-cn": "加蓬 +241",
    "label_zh-tw": "加蓬 +241",
    "pinyin": "jiapeng",
    "value": "241",
    "key": 69
  },
  {
    "label_en": "Equatorial Guinea +240",
    "label_zh-cn": "赤道几内亚 +240",
    "label_zh-tw": "赤道幾內亞 +240",
    "pinyin": "chidaojineiya",
    "value": "240",
    "key": 70
  },
  {
    "label_en": "Principe +239",
    "label_zh-cn": "普林西比 +239",
    "label_zh-tw": "普林西比 +239",
    "pinyin": "pulinxibi",
    "value": "239",
    "key": 71
  },
  {
    "label_en": "Sao Tome and Principe +239",
    "label_zh-cn": "圣多美 +239",
    "label_zh-tw": "聖多美 +239",
    "pinyin": "shengduomei",
    "value": "239",
    "key": 72
  },
  {
    "label_en": "Cape Verde +238",
    "label_zh-cn": "佛得角 +238",
    "label_zh-tw": "佛得角 +238",
    "pinyin": "fodejiao",
    "value": "238",
    "key": 73
  },
  {
    "label_en": "Cameroon +237",
    "label_zh-cn": "喀麦隆 +237",
    "label_zh-tw": "喀麥隆 +237",
    "pinyin": "kamailong",
    "value": "237",
    "key": 74
  },
  {
    "label_en": "Central African Republic +236",
    "label_zh-cn": "中非 +236",
    "label_zh-tw": "中非 +236",
    "pinyin": "zhongfei",
    "value": "236",
    "key": 75
  },
  {
    "label_en": "Chad +235",
    "label_zh-cn": "乍得 +235",
    "label_zh-tw": "乍得 +235",
    "pinyin": "zhade",
    "value": "235",
    "key": 76
  },
  {
    "label_en": "Nigeria +234",
    "label_zh-cn": "尼日利亚 +234",
    "label_zh-tw": "尼日利亞 +234",
    "pinyin": "niriliya",
    "value": "234",
    "key": 77
  },
  {
    "label_en": "Ghana +233",
    "label_zh-cn": "加纳 +233",
    "label_zh-tw": "加納 +233",
    "pinyin": "jiana",
    "value": "233",
    "key": 78
  },
  {
    "label_en": "Sierra Leone +232",
    "label_zh-cn": "塞拉利昂 +232",
    "label_zh-tw": "塞拉利昂 +232",
    "pinyin": "sailaliang",
    "value": "232",
    "key": 79
  },
  {
    "label_en": "Liberia +231",
    "label_zh-cn": "利比里亚 +231",
    "label_zh-tw": "利比裏亞 +231",
    "pinyin": "libiliya",
    "value": "231",
    "key": 80
  },
  {
    "label_en": "Mauritius +230",
    "label_zh-cn": "毛里求斯 +230",
    "label_zh-tw": "毛裏求斯 +230",
    "pinyin": "maoliqiusi",
    "value": "230",
    "key": 81
  },
  {
    "label_en": "Benin +229",
    "label_zh-cn": "贝宁 +229",
    "label_zh-tw": "貝寧 +229",
    "pinyin": "beining",
    "value": "229",
    "key": 82
  },
  {
    "label_en": "Togo +228",
    "label_zh-cn": "多哥 +228",
    "label_zh-tw": "多哥 +228",
    "pinyin": "duoge",
    "value": "228",
    "key": 83
  },
  {
    "label_en": "Niger +227",
    "label_zh-cn": "尼日尔 +227",
    "label_zh-tw": "尼日爾 +227",
    "pinyin": "nirier",
    "value": "227",
    "key": 84
  },
  {
    "label_en": "Burkina Faso +226",
    "label_zh-cn": "布基拉法索 +226",
    "label_zh-tw": "布基拉法索 +226",
    "pinyin": "bujilafasuo",
    "value": "226",
    "key": 85
  },
  {
    "label_en": "Cote dIvoire +225",
    "label_zh-cn": "科特迪瓦 +225",
    "label_zh-tw": "科特迪瓦 +225",
    "pinyin": "ketediwa",
    "value": "225",
    "key": 86
  },
  {
    "label_en": "Guinea +224",
    "label_zh-cn": "几内亚 +224",
    "label_zh-tw": "幾內亞 +224",
    "pinyin": "jineiya",
    "value": "224",
    "key": 87
  },
  {
    "label_en": "Mali +223",
    "label_zh-cn": "马里 +223",
    "label_zh-tw": "馬裏 +223",
    "pinyin": "mali",
    "value": "223",
    "key": 88
  },
  {
    "label_en": "Mauritania +222",
    "label_zh-cn": "毛里塔尼亚 +222",
    "label_zh-tw": "毛裏塔尼亞 +222",
    "pinyin": "maolitaniya",
    "value": "222",
    "key": 89
  },
  {
    "label_en": "Senegal +221",
    "label_zh-cn": "塞内加尔 +221",
    "label_zh-tw": "塞內加爾 +221",
    "pinyin": "saineijiaer",
    "value": "221",
    "key": 90
  },
  {
    "label_en": "Gambia +220",
    "label_zh-cn": "冈比亚 +220",
    "label_zh-tw": "岡比亞 +220",
    "pinyin": "gangbiya",
    "value": "220",
    "key": 91
  },
  {
    "label_en": "Libya +218",
    "label_zh-cn": "利比亚 +218",
    "label_zh-tw": "利比亞 +218",
    "pinyin": "libiya",
    "value": "218",
    "key": 92
  },
  {
    "label_en": "Tunisia +216",
    "label_zh-cn": "突尼斯 +216",
    "label_zh-tw": "突尼斯 +216",
    "pinyin": "tunisi",
    "value": "216",
    "key": 93
  },
  {
    "label_en": "Algeria +213",
    "label_zh-cn": "阿尔及利亚 +213",
    "label_zh-tw": "阿爾及利亞 +213",
    "pinyin": "aerjiliya",
    "value": "213",
    "key": 94
  },
  {
    "label_en": "Morocco +212",
    "label_zh-cn": "摩洛哥 +212",
    "label_zh-tw": "摩洛哥 +212",
    "pinyin": "moluoge",
    "value": "212",
    "key": 95
  },
  {
    "label_en": "South Africa +27",
    "label_zh-cn": "南非 +27",
    "label_zh-tw": "南非 +27",
    "pinyin": "nanfei",
    "value": "27",
    "key": 96
  },
  {
    "label_en": "Egypt +20",
    "label_zh-cn": "埃及 +20",
    "label_zh-tw": "埃及 +20",
    "pinyin": "aiji",
    "value": "20",
    "key": 97
  },
  {
    "label_en": "Uruguay +598",
    "label_zh-cn": "乌拉圭 +598",
    "label_zh-tw": "烏拉圭 +598",
    "pinyin": "wulagui",
    "value": "598",
    "key": 98
  },
  {
    "label_en": "Suriname +597",
    "label_zh-cn": "苏里南 +597",
    "label_zh-tw": "蘇裏南 +597",
    "pinyin": "sulinan",
    "value": "597",
    "key": 99
  },
  {
    "label_en": "Martinique +596",
    "label_zh-cn": "马提尼克 +596",
    "label_zh-tw": "馬提尼克 +596",
    "pinyin": "matinike",
    "value": "596",
    "key": 100
  },
  {
    "label_en": "Paraguay +595",
    "label_zh-cn": "巴拉圭 +595",
    "label_zh-tw": "巴拉圭 +595",
    "pinyin": "balagui",
    "value": "595",
    "key": 101
  },
  {
    "label_en": "French Guiana +594",
    "label_zh-cn": "法属圭亚那 +594",
    "label_zh-tw": "法屬圭亞那 +594",
    "pinyin": "fashuguiyana",
    "value": "594",
    "key": 102
  },
  {
    "label_en": "Ecuador +593",
    "label_zh-cn": "厄瓜多尔 +593",
    "label_zh-tw": "厄瓜多爾 +593",
    "pinyin": "eguaduoer",
    "value": "593",
    "key": 103
  },
  {
    "label_en": "Guyana +592",
    "label_zh-cn": "圭亚那 +592",
    "label_zh-tw": "圭亞那 +592",
    "pinyin": "guiyana",
    "value": "592",
    "key": 104
  },
  {
    "label_en": "Bolivia +591",
    "label_zh-cn": "玻利维亚 +591",
    "label_zh-tw": "玻利維亞 +591",
    "pinyin": "boliweiya",
    "value": "591",
    "key": 105
  },
  {
    "label_en": "Guadeloupe +590",
    "label_zh-cn": "瓜德罗普 +590",
    "label_zh-tw": "瓜德羅普 +590",
    "pinyin": "guadeluopu",
    "value": "590",
    "key": 106
  },
  {
    "label_en": "Haiti +509",
    "label_zh-cn": "海地 +509",
    "label_zh-tw": "海地 +509",
    "pinyin": "haidi",
    "value": "509",
    "key": 107
  },
  {
    "label_en": "Saint Pierre +508",
    "label_zh-cn": "圣彼埃尔 +508",
    "label_zh-tw": "聖彼埃爾 +508",
    "pinyin": "shengbiaier",
    "value": "508",
    "key": 108
  },
  {
    "label_en": "Panama +507",
    "label_zh-cn": "巴拿马 +507",
    "label_zh-tw": "巴拿馬 +507",
    "pinyin": "banama",
    "value": "507",
    "key": 109
  },
  {
    "label_en": "Costa Rica +506",
    "label_zh-cn": "哥斯达黎加 +506",
    "label_zh-tw": "哥斯達黎加 +506",
    "pinyin": "gesidalijia",
    "value": "506",
    "key": 110
  },
  {
    "label_en": "Nicaragua +505",
    "label_zh-cn": "尼加拉瓜 +505",
    "label_zh-tw": "尼加拉瓜 +505",
    "pinyin": "nijialagua",
    "value": "505",
    "key": 111
  },
  {
    "label_en": "Honduras +504",
    "label_zh-cn": "洪都拉斯 +504",
    "label_zh-tw": "洪都拉斯 +504",
    "pinyin": "hongdulasi",
    "value": "504",
    "key": 112
  },
  {
    "label_en": "El Salvador +503",
    "label_zh-cn": "萨尔瓦多 +503",
    "label_zh-tw": "薩爾瓦多 +503",
    "pinyin": "saerwaduo",
    "value": "503",
    "key": 113
  },
  {
    "label_en": "Guatemala +502",
    "label_zh-cn": "危地马拉 +502",
    "label_zh-tw": "危地馬拉 +502",
    "pinyin": "weidimala",
    "value": "502",
    "key": 114
  },
  {
    "label_en": "Belize +501",
    "label_zh-cn": "伯利兹 +501",
    "label_zh-tw": "伯利茲 +501",
    "pinyin": "bolizi",
    "value": "501",
    "key": 115
  },
  {
    "label_en": "Falkland Islands +500",
    "label_zh-cn": "福克兰群岛 +500",
    "label_zh-tw": "福克蘭群島 +500",
    "pinyin": "fukelanqundao",
    "value": "500",
    "key": 116
  },
  {
    "label_en": "Venezuela +58",
    "label_zh-cn": "委内瑞拉 +58",
    "label_zh-tw": "委內瑞拉 +58",
    "pinyin": "weineiruila",
    "value": "58",
    "key": 117
  },
  {
    "label_en": "Colombia +57",
    "label_zh-cn": "哥伦比亚 +57",
    "label_zh-tw": "哥倫比亞 +57",
    "pinyin": "gelunbiya",
    "value": "57",
    "key": 118
  },
  {
    "label_en": "Chile +56",
    "label_zh-cn": "智利 +56",
    "label_zh-tw": "智利 +56",
    "pinyin": "zhili",
    "value": "56",
    "key": 119
  },
  {
    "label_en": "Brazil +55",
    "label_zh-cn": "巴西 +55",
    "label_zh-tw": "巴西 +55",
    "pinyin": "baxi",
    "value": "55",
    "key": 120
  },
  {
    "label_en": "Argentina +54",
    "label_zh-cn": "阿根廷 +54",
    "label_zh-tw": "阿根廷 +54",
    "pinyin": "agenting",
    "value": "54",
    "key": 121
  },
  {
    "label_en": "Cuba +53",
    "label_zh-cn": "古巴 +53",
    "label_zh-tw": "古巴 +53",
    "pinyin": "guba",
    "value": "53",
    "key": 122
  },
  {
    "label_en": "Mexico +52",
    "label_zh-cn": "墨西哥 +52",
    "label_zh-tw": "墨西哥 +52",
    "pinyin": "moxige",
    "value": "52",
    "key": 123
  },
  {
    "label_en": "Peru +51",
    "label_zh-cn": "秘鲁 +51",
    "label_zh-tw": "秘魯 +51",
    "pinyin": "milu",
    "value": "51",
    "key": 124
  },
  {
    "label_en": "Liechtenstein +423",
    "label_zh-cn": "列支敦士登 +423",
    "label_zh-tw": "列支敦士登 +423",
    "pinyin": "liezhidunshideng",
    "value": "423",
    "key": 125
  },
  {
    "label_en": "Slovakia +421",
    "label_zh-cn": "斯洛伐克 +421",
    "label_zh-tw": "斯洛伐克 +421",
    "pinyin": "siluofake",
    "value": "421",
    "key": 126
  },
  {
    "label_en": "Czech Republic +420",
    "label_zh-cn": "捷克 +420",
    "label_zh-tw": "捷克 +420",
    "pinyin": "jieke",
    "value": "420",
    "key": 127
  },
  {
    "label_en": "Vatican +379",
    "label_zh-cn": "梵蒂冈 +379",
    "label_zh-tw": "梵蒂岡 +379",
    "pinyin": "fandigang",
    "value": "379",
    "key": 128
  },
  {
    "label_en": "Macedonia +389",
    "label_zh-cn": "马其顿 +389",
    "label_zh-tw": "馬其頓 +389",
    "pinyin": "maqidun",
    "value": "389",
    "key": 129
  },
  {
    "label_en": "Bosnia and Herzegovina +387",
    "label_zh-cn": "波黑 +387",
    "label_zh-tw": "波黑 +387",
    "pinyin": "bohei",
    "value": "387",
    "key": 130
  },
  {
    "label_en": "Slovenia +386",
    "label_zh-cn": "斯洛文尼亚 +386",
    "label_zh-tw": "斯洛文尼亞 +386",
    "pinyin": "siluowenniya",
    "value": "386",
    "key": 131
  },
  {
    "label_en": "Croatia +385",
    "label_zh-cn": "克罗地亚 +385",
    "label_zh-tw": "克羅地亞 +385",
    "pinyin": "keluodiya",
    "value": "385",
    "key": 132
  },
  {
    "label_en": "Ukraine +380",
    "label_zh-cn": "乌克兰 +380",
    "label_zh-tw": "烏克蘭 +380",
    "pinyin": "wukelan",
    "value": "380",
    "key": 133
  },
  {
    "label_en": "Andorra +376",
    "label_zh-cn": "安道尔共和国 +376",
    "label_zh-tw": "安道爾共和國 +376",
    "pinyin": "andaoergongheguo",
    "value": "376",
    "key": 134
  },
  {
    "label_en": "Moldova +373",
    "label_zh-cn": "摩尔多瓦 +373",
    "label_zh-tw": "摩爾多瓦 +373",
    "pinyin": "moerduowa",
    "value": "373",
    "key": 135
  },
  {
    "label_en": "Estonia +372",
    "label_zh-cn": "爱沙尼亚 +372",
    "label_zh-tw": "愛沙尼亞 +372",
    "pinyin": "aishaniya",
    "value": "372",
    "key": 136
  },
  {
    "label_en": "Latvia +371",
    "label_zh-cn": "拉脱维亚 +371",
    "label_zh-tw": "拉脫維亞 +371",
    "pinyin": "latuoweiya",
    "value": "371",
    "key": 137
  },
  {
    "label_en": "Lithuania +370",
    "label_zh-cn": "立陶宛 +370",
    "label_zh-tw": "立陶宛 +370",
    "pinyin": "litaowan",
    "value": "370",
    "key": 138
  },
  {
    "label_en": "Bulgaria +359",
    "label_zh-cn": "保加利亚 +359",
    "label_zh-tw": "保加利亞 +359",
    "pinyin": "baojialiya",
    "value": "359",
    "key": 139
  },
  {
    "label_en": "Finland +358",
    "label_zh-cn": "芬兰 +358",
    "label_zh-tw": "芬蘭 +358",
    "pinyin": "fenlan",
    "value": "358",
    "key": 140
  },
  {
    "label_en": "Cyprus +357",
    "label_zh-cn": "塞浦路斯 +357",
    "label_zh-tw": "塞浦路斯 +357",
    "pinyin": "saipulusi",
    "value": "357",
    "key": 141
  },
  {
    "label_en": "Malta +356",
    "label_zh-cn": "马耳他 +356",
    "label_zh-tw": "馬耳他 +356",
    "pinyin": "maerta",
    "value": "356",
    "key": 142
  },
  {
    "label_en": "Albania +355",
    "label_zh-cn": "阿尔巴尼亚 +355",
    "label_zh-tw": "阿爾巴尼亞 +355",
    "pinyin": "aerbaniya",
    "value": "355",
    "key": 143
  },
  {
    "label_en": "Iceland +354",
    "label_zh-cn": "冰岛 +354",
    "label_zh-tw": "冰島 +354",
    "pinyin": "bingdao",
    "value": "354",
    "key": 144
  },
  {
    "label_en": "Ireland +353",
    "label_zh-cn": "爱尔兰 +353",
    "label_zh-tw": "愛爾蘭 +353",
    "pinyin": "aierlan",
    "value": "353",
    "key": 145
  },
  {
    "label_en": "Luxembourg +352",
    "label_zh-cn": "卢森堡 +352",
    "label_zh-tw": "盧森堡 +352",
    "pinyin": "lusenbao",
    "value": "352",
    "key": 146
  },
  {
    "label_en": "Portugal +351",
    "label_zh-cn": "葡萄牙 +351",
    "label_zh-tw": "葡萄牙 +351",
    "pinyin": "putaoya",
    "value": "351",
    "key": 147
  },
  {
    "label_en": "Gibraltar +350",
    "label_zh-cn": "直布罗陀 +350",
    "label_zh-tw": "直布羅陀 +350",
    "pinyin": "zhibuluotuo",
    "value": "350",
    "key": 148
  },
  {
    "label_en": "Yugoslavia +381",
    "label_zh-cn": "南斯拉夫 +381",
    "label_zh-tw": "南斯拉夫 +381",
    "pinyin": "nansilafu",
    "value": "381",
    "key": 149
  },
  {
    "label_en": "San Marino +223",
    "label_zh-cn": "圣马力诺 +223",
    "label_zh-tw": "聖馬力諾 +223",
    "pinyin": "shengmalinuo",
    "value": "223",
    "key": 150
  },
  {
    "label_en": "Poland +48",
    "label_zh-cn": "波兰 +48",
    "label_zh-tw": "波蘭 +48",
    "pinyin": "bolan",
    "value": "48",
    "key": 151
  },
  {
    "label_en": "Norway +47",
    "label_zh-cn": "挪威 +47",
    "label_zh-tw": "挪威 +47",
    "pinyin": "nuowei",
    "value": "47",
    "key": 152
  },
  {
    "label_en": "Sweden +46",
    "label_zh-cn": "瑞典 +46",
    "label_zh-tw": "瑞典 +46",
    "pinyin": "ruidian",
    "value": "46",
    "key": 153
  },
  {
    "label_en": "Denmark +45",
    "label_zh-cn": "丹麦 +45",
    "label_zh-tw": "丹麥 +45",
    "pinyin": "danmai",
    "value": "45",
    "key": 154
  },
  {
    "label_en": "Austria +43",
    "label_zh-cn": "奥地利 +43",
    "label_zh-tw": "奧地利 +43",
    "pinyin": "aodili",
    "value": "43",
    "key": 155
  },
  {
    "label_en": "Switzerland +41",
    "label_zh-cn": "瑞士 +41",
    "label_zh-tw": "瑞士 +41",
    "pinyin": "ruishi",
    "value": "41",
    "key": 156
  },
  {
    "label_en": "Romania +40",
    "label_zh-cn": "罗马尼亚 +40",
    "label_zh-tw": "羅馬尼亞 +40",
    "pinyin": "luomaniya",
    "value": "40",
    "key": 157
  },
  {
    "label_en": "Hungary +36",
    "label_zh-cn": "匈牙利 +36",
    "label_zh-tw": "匈牙利 +36",
    "pinyin": "xiongyali",
    "value": "36",
    "key": 158
  },
  {
    "label_en": "Spain +34",
    "label_zh-cn": "西班牙 +34",
    "label_zh-tw": "西班牙 +34",
    "pinyin": "xibanya",
    "value": "34",
    "key": 159
  },
  {
    "label_en": "Belgium +32",
    "label_zh-cn": "比利时 +32",
    "label_zh-tw": "比利時 +32",
    "pinyin": "bilishi",
    "value": "32",
    "key": 160
  },
  {
    "label_en": "Netherlands +31",
    "label_zh-cn": "荷兰 +31",
    "label_zh-tw": "荷蘭 +31",
    "pinyin": "helan",
    "value": "31",
    "key": 161
  },
  {
    "label_en": "Greece +30",
    "label_zh-cn": "希腊 +30",
    "label_zh-tw": "希臘 +30",
    "pinyin": "xila",
    "value": "30",
    "key": 162
  },
  {
    "label_en": "Russia +7",
    "label_zh-cn": "俄罗斯 +7",
    "label_zh-tw": "俄羅斯 +7",
    "pinyin": "eluosi",
    "value": "7",
    "key": 163
  },
  {
    "label_en": "France +33",
    "label_zh-cn": "法国 +33",
    "label_zh-tw": "法國 +33",
    "pinyin": "faguo",
    "value": "33",
    "key": 164
  },
  {
    "label_en": "Italy +39",
    "label_zh-cn": "意大利 +39",
    "label_zh-tw": "意大利 +39",
    "pinyin": "yidali",
    "value": "39",
    "key": 165
  },
  {
    "label_en": "Germany +49",
    "label_zh-cn": "德国 +49",
    "label_zh-tw": "德國 +49",
    "pinyin": "deguo",
    "value": "49",
    "key": 166
  },
  {
    "label_en": "United Kingdom +44",
    "label_zh-cn": "英国 +44",
    "label_zh-tw": "英國 +44",
    "pinyin": "yingguo",
    "value": "44",
    "key": 167
  },
  {
    "label_en": "Uzbekistan +998",
    "label_zh-cn": "乌兹别克斯坦 +998",
    "label_zh-tw": "烏茲別克斯坦 +998",
    "pinyin": "wuzibiekesitan",
    "value": "998",
    "key": 168
  },
  {
    "label_en": "Kyrgyzstan +996",
    "label_zh-cn": "吉尔吉斯斯坦 +996",
    "label_zh-tw": "吉爾吉斯斯坦 +996",
    "pinyin": "jierjisisitan",
    "value": "996",
    "key": 169
  },
  {
    "label_en": "Georgia +995",
    "label_zh-cn": "乔治亚 +995",
    "label_zh-tw": "喬治亞 +995",
    "pinyin": "qiaozhiya",
    "value": "995",
    "key": 170
  },
  {
    "label_en": "Azerbaijan +994",
    "label_zh-cn": "阿塞拜疆 +994",
    "label_zh-tw": "阿塞拜疆 +994",
    "pinyin": "asaibaijiang",
    "value": "994",
    "key": 171
  },
  {
    "label_en": "Turkmenistan +993",
    "label_zh-cn": "土库曼斯坦 +993",
    "label_zh-tw": "土庫曼斯坦 +993",
    "pinyin": "tukumansitan",
    "value": "993",
    "key": 172
  },
  {
    "label_en": "Nepal +977",
    "label_zh-cn": "尼泊尔 +977",
    "label_zh-tw": "尼泊爾 +977",
    "pinyin": "niboer",
    "value": "977",
    "key": 173
  },
  {
    "label_en": "Mongolia +976",
    "label_zh-cn": "蒙古 +976",
    "label_zh-tw": "蒙古 +976",
    "pinyin": "menggu",
    "value": "976",
    "key": 174
  },
  {
    "label_en": "Bhutan +975",
    "label_zh-cn": "不丹 +975",
    "label_zh-tw": "不丹 +975",
    "pinyin": "budan",
    "value": "975",
    "key": 175
  },
  {
    "label_en": "Qatar +974",
    "label_zh-cn": "卡塔尔 +974",
    "label_zh-tw": "卡塔爾 +974",
    "pinyin": "kataer",
    "value": "974",
    "key": 176
  },
  {
    "label_en": "Bahrain +973",
    "label_zh-cn": "巴林 +973",
    "label_zh-tw": "巴林 +973",
    "pinyin": "balin",
    "value": "973",
    "key": 177
  },
  {
    "label_en": "Israel +972",
    "label_zh-cn": "以色列 +972",
    "label_zh-tw": "以色列 +972",
    "pinyin": "yiselie",
    "value": "972",
    "key": 178
  },
  {
    "label_en": "United Arab Emirates +971",
    "label_zh-cn": "阿联酋 +971",
    "label_zh-tw": "阿聯酋 +971",
    "pinyin": "alianqiu",
    "value": "971",
    "key": 179
  },
  {
    "label_en": "Palestine +970",
    "label_zh-cn": "巴勒斯坦 +970",
    "label_zh-tw": "巴勒斯坦 +970",
    "pinyin": "balesitan",
    "value": "970",
    "key": 180
  },
  {
    "label_en": "Oman +968",
    "label_zh-cn": "阿曼 +968",
    "label_zh-tw": "阿曼 +968",
    "pinyin": "aman",
    "value": "968",
    "key": 181
  },
  {
    "label_en": "Yemen +967",
    "label_zh-cn": "也门 +967",
    "label_zh-tw": "也門 +967",
    "pinyin": "yemen",
    "value": "967",
    "key": 182
  },
  {
    "label_en": "Saudi Arabia +966",
    "label_zh-cn": "沙特阿拉伯 +966",
    "label_zh-tw": "沙特阿拉伯 +966",
    "pinyin": "shatealabo",
    "value": "966",
    "key": 183
  },
  {
    "label_en": "Kuwait +965",
    "label_zh-cn": "科威特 +965",
    "label_zh-tw": "科威特 +965",
    "pinyin": "keweite",
    "value": "965",
    "key": 184
  },
  {
    "label_en": "Iraq +964",
    "label_zh-cn": "伊拉克 +964",
    "label_zh-tw": "伊拉克 +964",
    "pinyin": "yilake",
    "value": "964",
    "key": 185
  },
  {
    "label_en": "Syrian Arab Republic +963",
    "label_zh-cn": "叙利亚 +963",
    "label_zh-tw": "敘利亞 +963",
    "pinyin": "xuliya",
    "value": "963",
    "key": 186
  },
  {
    "label_en": "Jordan +962",
    "label_zh-cn": "约旦 +962",
    "label_zh-tw": "約旦 +962",
    "pinyin": "yuedan",
    "value": "962",
    "key": 187
  },
  {
    "label_en": "Lebanon +961",
    "label_zh-cn": "黎巴嫩 +961",
    "label_zh-tw": "黎巴嫩 +961",
    "pinyin": "libanen",
    "value": "961",
    "key": 188
  },
  {
    "label_en": "Maldives +960",
    "label_zh-cn": "马尔代夫 +960",
    "label_zh-tw": "馬爾代夫 +960",
    "pinyin": "maerdaifu",
    "value": "960",
    "key": 189
  },
  {
    "label_en": "Bangladesh +880",
    "label_zh-cn": "孟加拉国 +880",
    "label_zh-tw": "孟加拉國 +880",
    "pinyin": "mengjialaguo",
    "value": "880",
    "key": 190
  },
  {
    "label_en": "Laos +856",
    "label_zh-cn": "老挝 +856",
    "label_zh-tw": "老撾 +856",
    "pinyin": "laowo",
    "value": "856",
    "key": 191
  },
  {
    "label_en": "Cambodia +855",
    "label_zh-cn": "柬埔寨 +855",
    "label_zh-tw": "柬埔寨 +855",
    "pinyin": "jianpuzhai",
    "value": "855",
    "key": 192
  },
  {
    "label_en": "North Korea +850",
    "label_zh-cn": "朝鲜 +850",
    "label_zh-tw": "朝鮮 +850",
    "pinyin": "chaoxian",
    "value": "850",
    "key": 193
  },
  {
    "label_en": "Brunei +673",
    "label_zh-cn": "文莱 +673",
    "label_zh-tw": "文萊 +673",
    "pinyin": "wenlai",
    "value": "673",
    "key": 194
  },
  {
    "label_en": "Timor-Leste +670",
    "label_zh-cn": "东帝汶 +670",
    "label_zh-tw": "東帝汶 +670",
    "pinyin": "dongdiwen",
    "value": "670",
    "key": 195
  },
  {
    "label_en": "Armenia +374",
    "label_zh-cn": "亚美尼亚 +374",
    "label_zh-tw": "亞美尼亞 +374",
    "pinyin": "yameiniya",
    "value": "374",
    "key": 196
  },
  {
    "label_en": "Iran +98",
    "label_zh-cn": "伊朗 +98",
    "label_zh-tw": "伊朗 +98",
    "pinyin": "yilang",
    "value": "98",
    "key": 197
  },
  {
    "label_en": "Myanmar +95",
    "label_zh-cn": "缅甸 +95",
    "label_zh-tw": "緬甸 +95",
    "pinyin": "miandian",
    "value": "95",
    "key": 198
  },
  {
    "label_en": "Sri Lanka +94",
    "label_zh-cn": "斯里兰卡 +94",
    "label_zh-tw": "斯裏蘭卡 +94",
    "pinyin": "sililanka",
    "value": "94",
    "key": 199
  },
  {
    "label_en": "Afghanistan +93",
    "label_zh-cn": "阿富汗 +93",
    "label_zh-tw": "阿富汗 +93",
    "pinyin": "afuhan",
    "value": "93",
    "key": 200
  },
  {
    "label_en": "Pakistan +92",
    "label_zh-cn": "巴基斯坦 +92",
    "label_zh-tw": "巴基斯坦 +92",
    "pinyin": "bajisitan",
    "value": "92",
    "key": 201
  },
  {
    "label_en": "India +91",
    "label_zh-cn": "印度 +91",
    "label_zh-tw": "印度 +91",
    "pinyin": "yindu",
    "value": "91",
    "key": 202
  },
  {
    "label_en": "Turkey +90",
    "label_zh-cn": "土耳其 +90",
    "label_zh-tw": "土耳其 +90",
    "pinyin": "tuerqi",
    "value": "90",
    "key": 203
  },
  {
    "label_en": "Vietnam +84",
    "label_zh-cn": "越南 +84",
    "label_zh-tw": "越南 +84",
    "pinyin": "yuenan",
    "value": "84",
    "key": 204
  },
  {
    "label_en": "Kazakhstan +7",
    "label_zh-cn": "哈萨克斯坦 +7",
    "label_zh-tw": "哈薩克斯坦 +7",
    "pinyin": "hasakesitan",
    "value": "7",
    "key": 205
  },
  {
    "label_en": "Tajikistan +7",
    "label_zh-cn": "塔吉克斯坦 +7",
    "label_zh-tw": "塔吉克斯坦 +7",
    "pinyin": "tajikesitan",
    "value": "7",
    "key": 206
  },
  {
    "label_en": "Thailand +66",
    "label_zh-cn": "泰国 +66",
    "label_zh-tw": "泰國 +66",
    "pinyin": "taiguo",
    "value": "66",
    "key": 207
  },
  {
    "label_en": "Singapore +65",
    "label_zh-cn": "新加坡 +65",
    "label_zh-tw": "新加坡 +65",
    "pinyin": "xinjiapo",
    "value": "65",
    "key": 208
  },
  {
    "label_en": "Philippines +63",
    "label_zh-cn": "菲律宾 +63",
    "label_zh-tw": "菲律賓 +63",
    "pinyin": "feilvbin",
    "value": "63",
    "key": 209
  },
  {
    "label_en": "Indonesia +62",
    "label_zh-cn": "印度尼西亚 +62",
    "label_zh-tw": "印度尼西亞 +62",
    "pinyin": "yindunixiya",
    "value": "62",
    "key": 210
  },
  {
    "label_en": "Malaysia +60",
    "label_zh-cn": "马来西亚 +60",
    "label_zh-tw": "馬來西亞 +60",
    "pinyin": "malaixiya",
    "value": "60",
    "key": 211
  }
]