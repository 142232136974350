<template>
  <a-config-provider>
    <template #renderEmpty>
      <div class="empty-wrapper flex">
        <a-empty :image="require('@/assets/order_none.png')">
          <span slot="description" class="empty-text"> {{$t('mine.order_10')}} </span>
        </a-empty>
      </div>
    </template>
    <div id="app">
      <navigation-bar v-if="!isApp"/>
      <keep-alive exclude="Details,Mine,Pay,Search,QRCode,Wallet">
        <router-view style="flex:1;" :key="$route.fullPath"/>
      </keep-alive>
      <footer-bar v-if="!isApp"/>
      <float-button/>
      <login-modal/>
      <coupon-dialogs :visible="inviteShow" :coupons="newUserCoupons" @close="inviteShow = false"/>
    </div>
  </a-config-provider>
</template>
<script>
import { mapState,mapGetters,mapActions } from 'vuex';
import LoginModal from './components/LoginModal.vue';
import FooterBar from './components/FooterBar.vue';
import NavigationBar from './components/NavigationBar.vue';
import FloatButton from './components/FloatButton.vue';
import CouponDialogs from './components/coupon-dialogs.vue';
import { GetNewUserCouponAPI } from './api/api';
import { ConfigProvider } from 'ant-design-vue';
export default {
  components: { 
    LoginModal,
    NavigationBar, 
    FooterBar, 
    CouponDialogs,
    FloatButton,
    [ConfigProvider.name]:ConfigProvider
  },
  data() {
    return {
      inviteShow: false,
      newUserCoupons:[]
    }
  },
  computed: {
    ...mapState(['isApp']),
    ...mapGetters('user', ['isLogin']),
    cachedViews() {
      // 获取需要缓存的页面标识符
      return this.$route.meta.keepAlive ? [this.$route.name] : []
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions('user', ['GetInfo']),
    async init(){
      const {invitecode} = this.$route.query
      if (invitecode) sessionStorage.setItem("invitecode", invitecode);
      if (this.isLogin&&!this.isApp) this.GetInfo() // 获取用户信息
      if (invitecode && !this.isLogin) this.handleNewUser()
    },
    async handleNewUser(){
      const { data:{ list }} = await GetNewUserCouponAPI()
      if (list.length) {
        this.newUserCoupons = list
        setTimeout(() => { this.inviteShow = true }, 2000)
      }
    }
  },
}
</script>
<style lang="scss">
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
