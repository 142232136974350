<template>
  <div class="comment-bar">
    <div class="container title">
      {{ $t('reviews.title') }}
      <div class="flex-center-start">
        {{ $t('reviews.add_up') }}
        <img class="star" src="../assets/reviews/star.png" alt="" srcset="">
        {{ $t('reviews.reviews') }}
      </div>		
    </div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="swiper-slide" v-for="(item,index) in reviews[$i18n.locale]" :key="index">
        <div>
          <div class="flex-center-between">
            <img class="icon" src="../assets/reviews/icon.png" alt="" srcset="">
            <span>{{ item.user }}</span>
          </div>
          <div class="content">{{ item.content }}</div>
        </div>
        <div class="flex-center-between">
          <img class="star" src="../assets/reviews/star.png" alt="" srcset="">
          <span class="flex">
            <img v-if="$i18n.locale === 'en'" class="flag" :src="item.icon" alt="" srcset="">
            {{ item.country }}
          </span>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  components:{
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        speed:2000,
        autoplay: {
          delay: 2000,
          pauseOnMouseEnter:true,
          waitForTransition:true,
          disableOnInteraction: false,
        },
        centeredSlides: true,
        breakpoints: { 
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 1.6,
            spaceBetween: 30
          },
          768: {  //当屏幕宽度大于等于768 
            slidesPerView: 3,
            spaceBetween: 40
          },
          1280: {  //当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 60
          }
        }
      },
      reviews:{
        'zh-cn':[
          {
            user:'Me****',
            country:'香港',
            content:'使用前还不会操作，客服团队服务很到位，会手把手指导步骤，使用中网络通畅，体验感觉很不错，以后会复购。'
          },
          {
            user:'Ni****',
            country:'英国',
            content:'这款eSIM真的易于使用和设置，我致电过客服热线，他们响应迅速并且提供了很大的帮助。'
          },
          {
            user:'Ge****',
            country:'塞尔维亚',
            content:'非常棒！易于安装，而且使用起来也很顺畅，让我度过了一次完美旅行。'
          },
          {
            user:'Pa****',
            country:'英国',
            content:'安装方便，立即生效。纽约大部分旅游地网络稳定。让我能与朋友们保持联系又能及时分享回忆！我强烈推荐 TraveleSIM。'
          },
          {
            user:'Nu**',
            country:'加拿大',
            content:'联网快且稳定，我们这次出游对发送或接收短信没有太大需求，而且WhatsApp、Instagram、Discord这些都可以顺畅使用，这是一次令人满意的体验。'
          },
          {
            user:'Th*****',
            country:'新西兰',
            content:'设置简单（三星 S22）且易于使用。当通讯信号弱时它确实一度停止工作，但我重启手机，它便又重新连接上了。使用起来没有什么麻烦，很高兴知道我可以随时随地上网。值得推荐！'
          },
          {
            user:'Tr***',
            country:'泰国',
            content:'我们在安装它时遇到了问题，但通过 WhatsApp 立即得到了回应，客服人员在我们安装过程中一步步的提供帮助。这是一款很棒的eSIM，我们可以顺畅使用谷歌地图走遍曼谷。下次度假时肯定会继续购买的。'
          },
        ],
        'zh-tw':[
          {
            user:'Me****',
            country:'澳门',
            content:'eSIM不用換SIM卡很方便，我們到當地先連Wifi設定使用，就不會擔心提前激活浪費了時長。而且價格比SIM卡優惠，在香港配合的是SmartTone。'
          },
          {
            user:'Ni****',
            country:'香港',
            content:'超值，單今年已買了4次，真的去香港方便又好用，完全是香港eSIM首選。'
          },
          {
            user:'Ge****',
            country:'德国',
            content:'這個esim卡非常棒，信號強，日常使用都沒卡頓的現象，強烈推薦!'
          },
          {
            user:'Pa****',
            country:'美國',
            content:'之前好少用eSIM，哩個易買易裝又好用，還可以開熱點同朋友一齊用！客服回復都算快，推薦新手朋友都可以好快操作到使用。買少咗，再買過新套餐即時又可以激活來用。'
          },
          {
            user:'Nu**',
            country:'香港',
            content:'性價比算不錯的，我用郵箱收的二維碼，在設置裏掃碼添加很快安裝好，網絡很順暢也挺穩定的，各款軟件什麼的都能用。已經推薦給經常出差的朋友了~直接在app下單好方便。'
          },
          {
            user:'Th*****',
            country:'澳大利亞',
            content:'同朋友一齊出來玩~去咗Temple of Olympian Zeus~流量用完啦又買過新套餐，3分鐘就setting完畢，網絡好穩定!'
          },
          {
            user:'Tr***',
            country:'香港',
            content:'整體使用感受很好，由香港開始開通，一直到深圳訊號都收得很好，而且最正係可以用WhatsApp睇Facebook，下次會再購買。'
          },
        ],
        'en':[
          {
            user:'Me****',
            country:'France',
            content:'Easy installation, works immediately. The network is stable and of good quality in most tourist places in New York. I highly recommend it to stay connected with those around you and share your memories!',
            icon:require('@/assets/reviews/France.png')
          },
          {
            user:'Ni****',
            country:'Switzerland',
            content:'Super fast internet, which is perfect if you spend a lot of time on foot and navigate with Google Maps.',
            icon:require('@/assets/reviews/Switzerland.png')
          },
          {
            user:'Ge****',
            country:'Germany',
            content:'Super easy and direct to handle the eSIM setting for a cell phone network.',
            icon:require('@/assets/reviews/Germany.png')
          },
          {
            user:'Nu**',
            country:'Portugal',
            content:'Was super easy to set up and never failed once!! Truly recommend as it made roaming around the city super easy!!',
            icon:require('@/assets/reviews/Portugal.png')
          },
          {
            user:'Pa****',
            country:'Belgium',
            content:'Fantastisch - goede werking - snel en correct',
            icon:require('@/assets/reviews/Belgium.png')
          },
          {
            user:'Th*****',
            country:'Réunion',
            content:'My trip is being postponed for personal reasons. But the cs assist with a quick response. So I highly recommend it Top-notch after-sales service.',
            icon:require('@/assets/reviews/France.png')
          },
          {
            user:'Tr***',
            country:'Germany',
            content:'I contacted support via the Telegram. The help came immediately and then it worked (unchecked the box when selecting the network and change the provider). Super fast network, available Bangkok, Chiang Mai.',
            icon:require('@/assets/reviews/Germany.png')
          },
        ]
      }
    }
  },
}
</script>
<style scoped lang="scss">
  .comment-bar{
    user-select: none;
    .title{
      color:#222222;
      font-size: 32px;
      line-height: 2;
      img{
        margin: 0 6px;
        width: 200px;
        height: auto;
      }
    }
    .swiper {
      padding: 80px 0;
      .swiper-slide {
        color: #333333;
        font-size: 18px;
        opacity: 0.4;
        height: 370px;
        padding: 40px;
        border-radius: 10px;
        box-shadow: 16px 16px 48px 0px rgba(0,0,0,0.1);
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .icon{
          width: 23px;
          height: auto;
        }
        .star{
          width: 100px;
          height: auto;
        }
        .flag{
          margin-right: 8px;
          width: 20px;
          height: auto;
        }
        .content{
          margin-top: 30px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7; /* 显示的行数 */
          overflow: hidden; /* 溢出隐藏 */
          text-overflow: ellipsis; /* 文本溢出显示省略号 */
        }
      }
      .swiper-slide-active{
        opacity: 1;
        transform: scale(1.1);
      }
      .swiper-slide-prev,.swiper-slide-next{
        opacity: 1;
      }
    }
    @include respond-to('phone'){
      .title{
        font-size: 18px;
        img{
          margin: 0 6px;
          width: 100px;
          height: auto;
        }
      }
      .swiper {
        padding: 40px 0;
        .swiper-slide {
          padding: 16px;
          font-size: 14px;
          height: 240px;
          .icon{
            width: 18px;
            height: auto;
          }
          .star{
            width: 60px;
            height: auto;
          }
          .flag{
            margin-right: 4px;
            width: 16px;
            height: auto;
          }
          .content{
            margin-top: 20px;
            -webkit-line-clamp: 5; /* 显示的行数 */
          }
        }
      
      }
      @media (max-width: 320px) {
        .title{
          font-size: 16px;
          line-height: 1.5;
          img{
            margin: 0 6px;
            width: 80px;
            height: auto;
          }
        }
        .swiper {
          .swiper-slide {
            padding: 10px;
            font-size: 12px;
            height: 200px;
            .icon{
              width: 16px;
              height: auto;
            }
           
          }
        }
      }
    }
    @include respond-to('pad'){
      .title{
        font-size: 20px;
        img{
          margin: 0 6px;
          width: 100px;
          height: auto;
        }
      }
      .swiper {
        padding: 40px 0;
        .swiper-slide {
          padding: 20px;
          font-size: 14px;
          height: 260px;
          .star{
            width: 70px;
            height: auto;
          }
          .flag{
            margin-right: 8px;
            width: 20px;
            height: auto;
          }
          .content{
            margin-top: 20px;
            -webkit-line-clamp: 6; /* 显示的行数 */
          }
        }
      }
    }
  }
</style>