import axios from "axios";
import store from "../store";
import router from '../router';
import MD5 from "md5";
import { message } from 'ant-design-vue';
function GETSIGN(obj) {
  obj = {
		client: 'h5',
		nonce_str: Math.random().toString(36).substring(2, 8),
		lang: localStorage.getItem("esim_lang")||'en',
		timestamp: new Date().getTime(),
		version: '1.0.0',
		...obj
	};
	let keySort = Object.keys(obj).sort();
	let str = keySort.filter(key => obj[key] || obj[key] === 0)
	.map(key => `${key}=${encodeURIComponent(obj[key])}`).join('&');
	str += 'sC*@5g+m'
	obj.sign = MD5(str).toUpperCase();
	return obj;
}

const request = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 60000,
  headers: { "Content-Type": "application/json" },
});
request.interceptors.request.use(config=> {
  if(config.method === 'get') {
    config.params = GETSIGN(config.params||{})
  }else{
    config.data = GETSIGN(config.data||{})
  }
  const token = store.state.user.token || "";
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
}, errorHandler);

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.request.status !== 200) {
    message.error(error.message)
  }
  return Promise.reject(error)
}
request.interceptors.response.use(response=> {
  const {code,msg} = response.data;
  if (code !== 1) {
    //401 token不对 402 其他终端登录
    const resultCode = [401,402]
    if(resultCode.includes(code)){
      store.dispatch('user/Reset',true);
      const currentRoute = router.currentRoute;
      const { name, query , meta:{requiresAuth} } = currentRoute;
      if(requiresAuth)store.commit('SET_REDIRECT',{ name, query },{root:true})
    }
    message.warning(msg)
    return Promise.reject(msg)
  }
  return response.data;
},errorHandler);


const bapi_request = axios.create({
  baseURL: process.env.VUE_APP_BAPI_API_HOST,
  timeout: 60000,
  headers: {"content-Type": "application/x-www-form-urlencoded"},
});
bapi_request.interceptors.request.use(config=> {
  return config;
}, errorHandler);
bapi_request.interceptors.response.use(response=> {
  const {code,msg} = response.data;
  if (code !== 1) {
    message.warning(msg)
    return Promise.reject(msg)
  }
  return response.data;
},errorHandler);
export {request,bapi_request};
