<template>
  <div class="footer-bar">
    <div class="container">
      <div class="footer-bar-title flex-center-between">
        <a-space align="center">
          <div class="logo">
            <img src="../assets/footer_logo.png" alt="" />
          </div>
          <a-divider type="vertical" class="divider"/>
          <div class="slogan">{{$t('common.slogan')}}</div>
          <a-button ghost shape="round" class="download" v-if="$route.name!=='Download'" @click="handleDownload">
            {{$t('footer.download')}}
          </a-button>
        </a-space>
        <div class="title">{{$t('footer.name')}}</div>
      </div>
      <div class="footer-bar-pc">
        <a-row type="flex" justify="space-between" :gutter="[40,{ xs:20, sm:16, md:16, lg:20, xl:40 }]">
          <!-- 公司地址 -->
          <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
            <a-row type="flex" :gutter="[{ xs:8, sm:16, md:50, lg:50, xl:40 },{ xs:16, sm:16, md:16, lg:20, xl:40 }]">
              <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div class="item">
                  <h4><i class="iconfont icon-dw"/>{{$t('footer.hk_name')}}</h4>
                  <p>{{$t('footer.hk_addess')}}</p>
                  <p><a href="tel:+852-5196-1035">+852-5196-1035</a></p>
                </div>
              </a-col>
              <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div class="item">
                  <h4><i class="iconfont icon-dw"/>{{$t('footer.sz_name')}}</h4>
                  <p>{{$t('footer.sz_addess')}}</p>
                  <p><a href="tel:+86-755-2267-3112">+86-755-2267-3112</a></p>
                </div>
              </a-col>
              <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div class="item">
                  <h4><i class="iconfont icon-dw"/>{{$t('footer.am_name')}}</h4>
                  <p>{{$t('footer.am_addess')}}</p>
                  <p><a href="tel:+853-6611-6270">+853-6611-6270</a></p>
                </div>
              </a-col>
              <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div class="item">
                  <h4><i class="iconfont icon-dw"/>{{$t('footer.tw_name')}}</h4>
                  <p>{{$t('footer.tw_addess')}}</p>
                  <p class="copy" data-clipboard-text="@521yokau" @click="handleCopy" >line: @521yokau</p>
                </div>
              </a-col>
            </a-row>
          </a-col>
          <!-- 链接 -->
          <a-col :xs="24" :sm="24" :md="8" :lg="7" :xl="6">
            <div class="item flex-column">
              <h4><i class="iconfont icon-lianjie"/>{{$t('footer.link_title')}}</h4>
              <a :href="`http://www.triproaming.com/?lang=${$i18n.locale}`" target="_blank">{{$t('footer.link_yyb')}}</a>
              <a :href="`http://sim.triproaming.cn/?lang=${$i18n.locale}`" target="_blank">{{$t('footer.link_wyb')}}</a>
              <a :href="`http://esim.triproaming.com/?lang=${$i18n.locale}`">{{$t('footer.link_esim')}}</a>
            </div>
          </a-col>
          <!-- 联系我们 -->
          <a-col :xs="24" :sm="24" :md="14" :lg="16" :xl="16">
            <div class="contact-wrap">
              <a-row type="flex" :gutter="[{ xs:8, sm:16, md:50, lg:50, xl:40 },{ xs:16, sm:16, md:16, lg:20, xl:40 }]">
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <div class="item">
                    <h4><i class="iconfont icon-mine"/>{{$t('footer.contact_us')}}</h4>
                    <a class="copy" data-clipboard-text="reservation@triproaming.com" @click="handleCopy">reservation@triproaming.com</a>
                  </div>
                </a-col>
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <div class="item">
                    <h4><i class="iconfont icon-huaban"/>{{$t('footer.commerce_contact')}}</h4>
                    <a class="copy" data-clipboard-text="agent@triproaming.com" @click="handleCopy">agent@triproaming.com</a>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <!-- 图标 -->
          <a-col :xs="24" :sm="24" :md="10" :lg="8" :xl="8">
            <a-row type="flex" align="middle">
              <!-- esim -->
              <a-col :span="4">
                <div class="app-icon">
                  <a-popover >
                    <template #content>
                      <div class="flex-center-column">
                        <span>{{ $t('footer.scan_app') }}</span>
                        <div class="qrcode-img">
                          <img v-show="hovered.esim" @load="handleImageLoad('esim')" src="https://esim.qn.triproaming.cn/esim-qrcode/icon_qrcode_esim.png" alt="" srcset="">
                        </div>
                      </div>
                    </template>
                    <img width="25" src="../assets/footer/icon_esim.png" />
                  </a-popover>
                </div>
              </a-col>
              <!-- 万游宝 -->
              <a-col :span="4">
                <div class="app-icon">
                  <a-popover >
                    <template #content>
                      <div class="flex-center-column">
                        <span>{{ $t('footer.scan_app') }}</span>
                        <div class="qrcode-img">
                          <img v-show="hovered.wyb" @load="handleImageLoad('wyb')" src="https://esim.qn.triproaming.cn/esim-qrcode/icon_qrcode_wyb.png" alt="" srcset="">
                        </div>
                      </div>
                    </template>
                    <img width="25" src="../assets/footer/icon_wyb.png" />
                  </a-popover>
                </div>
              </a-col>
              <!-- 公众号 -->
              <a-col :span="4">
                <div class="app-icon">
                  <a-popover :overlay-inner-style="{ padding: 0 }">
                    <template #content>
                      <div class="flex-center-column">
                        <span>{{ $t('footer.scan_wechat') }}</span>
                        <div class="qrcode-img">
                          <img v-show="hovered.wechat" @load="handleImageLoad('wechat')" src="https://esim.qn.triproaming.cn/esim-qrcode/icon_qrcode_wechat.png" alt="" srcset="">
                        </div>
                      </div>
                    </template>
                    <img width="25" src="../assets/footer/icon_wechat.png" />
                  </a-popover>
                </div>
              </a-col>
              <!-- ins -->
              <a-col :span="4">
                <div class="app-icon">
                  <a href="https://www.instagram.com/travelesim_triproaming/" target="_blank" rel="noopener noreferrer">
                    <img width="25" src="../assets/footer/icon_ins.png" />
                  </a>
                </div>
              </a-col>
              <!-- facebook -->
              <a-col :span="4">
                <div class="app-icon">
                  <a href="https://www.facebook.com/TraveleSIM" target="_blank" rel="noopener noreferrer">
                    <img width="25" src="../assets/footer/icon_facebook.png" />
                  </a>
                </div>
              </a-col>
              <!-- tiktok -->
              <a-col :span="4">
                <div class="app-icon">
                  <a href="https://www.tiktok.com/@travelesim_" target="_blank" rel="noopener noreferrer">
                    <img width="25" src="../assets/footer/icon_tiktok.png" />
                  </a>
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        <a-row type="flex" justify="space-between" :gutter="[20,10]">
          <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <span>Copyright © {{$t('footer.name')}} {{ $t('footer.rights') }}</span>
            <router-link to="/treaty">{{ $t('login.treaty_1') }}</router-link>
            <router-link to="/privacy">{{ $t('login.treaty_2') }}</router-link>
          </a-col>
          <!-- <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <a href="https://beian.miit.gov.cn/" target="_blank">{{icpNumber}}</a>
            <span>粤公安网备 440011225858</span>
            <span>增值电信业务经营许可证 粤B2-20210862</span>
          </a-col> -->
        </a-row> 
      </div>
    </div>
  </div>
</template>
<script>
import Clipboard from 'clipboard'; 
import { message,Popover } from 'ant-design-vue';
export default {
  name: "FooterBar",
  components: {
    [Popover.name]: Popover,
  },
  data() {
    return {
      icpNumber:'',
      hovered:{
        esim:false,
        wyb:false,
        wechat:false,
      },
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    init(){
      const domain = window.location.hostname;
      if (/\.cn$/.test(domain)) {
        // 域名以.cn结尾
        this.icpNumber = '粤ICP备17127815号-1'
      } else if (/\.com$/.test(domain)) {
        // 域名以.com结尾
        this.icpNumber = '粤ICP备17127815号-2'
      } else {
        // 域名既不以.com结尾也不以.cn结尾
        this.icpNumber = '粤ICP备17127815号-1'
      }
    },
    handleCopy () {  
      let clipboard = new Clipboard('.copy')  
      clipboard.on('success', e => {  
        message.success(this.$t('mine.flow_3'))  
        clipboard.destroy()  
      })  
      clipboard.on('error', e => {  
        message.warning(this.$t('mine.flow_4'))  
        clipboard.destroy()  
      })  
    },
    handleImageLoad(key){
      setTimeout(() => {
        this.hovered[key] = true
      }, 500);
    },
    handleDownload(){
      this.$router.push({name:'Download'})
    }
  }
};
</script>
<style lang="scss" scoped>
.qrcode-img{
  width: 120px;
}
.footer-bar-title{
  padding: 20px 10px;
  /deep/ .ant-space{
    width: 100%;
    justify-content: center;
    @media (max-width: 320px){
      flex-wrap: wrap;
      .ant-space-item{
        margin-right: 0!important;
      }
    }
  }
  .logo{
    width: 120px;
    @media (max-width: 320px){
      margin-bottom: 10px;
    }
  }
  .divider{
    display: none;
  }
  .slogan{
    display: none;
    font-size: 18px;
  }
  .title{
    display: none;
    margin-left: 10px;
    font-size: 20px;
  }
  .download{
    font-size: 12px;
    height: 36px;
    &:active,&:hover{
      color: #fff;
      border-color: #fff;
      box-shadow: none;
    }
  }
  @media (max-width: 320px){
    padding: 10px;
  }
  @media (min-width: 769px){
    padding: 20px 0;
    /deep/ .ant-space{
      width: initial;
      justify-content: initial;
    }
    .logo{
      width: 142px;
    }
    .title{
      display: block;
    }
    .download{
      display: none;
    }
  }
  @media (min-width: 992px){
    margin-bottom: 20px;
    border-bottom: 1px solid #fff;
    .slogan{
      display: block;
    }
    .divider{
      display: block;
    }
  }
}

.footer-bar-pc{
  display: none;
  padding: 10px 20px 40px 20px;
  @media (min-width: 992px){
    display: block;
  }
}
.footer-bar{
  color: #fff;
  background-color: $color;
  .item{
    line-height: 2;
    h4{
      color: #fff;
      font-size: 16px;
      position: relative;
      .iconfont{
        position: absolute;
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
      }
    }
    .copy{
      cursor: pointer;
      &:hover{
        color: #00da00;
      }
    }
    p,a{
      font-size: 14px;
    }
    a:hover{
      color: #00da00;
    }
  }
  .contact-wrap{
    height: 60px;
  }
  .app-icon{
    padding: 6px;
    img{
      cursor: pointer;
    }
  }
  @include respond-to("phone"){
    h1{
      font-size: 18px;
      padding: 5px;
      margin-bottom: 15px;
    }
    .contact-wrap{
      height: auto;
    }
  }
  @include respond-to("pad"){
    .contact-wrap{
      height: 60px;
    }
  }
}
.copyright{
  color: #999999;
  padding: 15px 10px;
  line-height: 1.5;
  font-size: 14px;
  background-color: #1c2024;
  span,a{
    margin-right:10px;
  }
  @include respond-to("phone"){
    span,a{
      font-size: 12px;
    }
  }
}

</style>