<template>
    <a-modal
      :visible="loginModalVisible"
      :footer="null"
      :width="410"
      :title="formTitle"
      :destroyOnClose="false"
      @cancel="handleCancel"
      wrapClassName="login-modal"
    >
    <a-spin :spinning="isLoading" size="large">
      <!-- 登录 -->
      <a-form-model v-show="formType === 'login'" :model="loginForm" :rules="loginRules" ref="LoginForm" @submit="handleLogin" @submit.native.prevent>
        <a-form-model-item prop="text">
          <a-input v-model="loginForm.text" allow-clear :placeholder="$t('login.tips_13')">
            <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="pwd">
          <a-input-password v-model="loginForm.pwd" allow-clear type="password" :placeholder="$t('login.tips_3')">
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: 0;">
          <a-button type="primary" block html-type="submit">
            {{ $t('login.title_1') }}
          </a-button>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: 0;">
        <div class="form-link flex-center-end">
          <span @click="formType = 'resetpwd'">{{ $t('login.title_3') }}</span>
          <a-divider type="vertical" style="height: 16px;"/>
          <span @click="formType = 'register'">{{ $t('login.title_2') }}</span>
        </div>
        </a-form-model-item>
      </a-form-model>
      <!-- 注册 -->
      <a-form-model v-show="formType === 'register'" :model="registerForm" :rules="registerRules" ref="RegisterForm" @submit="handleRegister" @submit.native.prevent>
        <div class="invite flex">{{ $t('login.invite') }}</div>
        <a-form-model-item prop="text">
          <a-auto-complete v-model="registerForm.text" allow-clear :placeholder="$t('login.tips_0')" @search="handleSearch">
            <a-input>
              <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
            </a-input>
            <template slot="dataSource">
              <a-select-option v-for="email in result" :key="email">
                {{ email }}
              </a-select-option>
            </template>
          </a-auto-complete>
        </a-form-model-item>
        <a-form-model-item prop="invitecode">
          <a-input v-model="registerForm.invitecode" allow-clear :placeholder="$t('login.tips_11')">
            <a-icon slot="prefix" type="smile" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="code">
          <a-input class="input-code" v-model="registerForm.code" allow-clear :placeholder="$t('login.tips_4')">
            <a-icon slot="prefix" type="message" style="color:rgba(0,0,0,.25)" />
            <a-button slot="addonAfter" type="link" class="btn-link" :loading="countdownLoading" @click="handleSendCode">
              {{ countingDown ? `${countdown} ${$t('login.tips_12')}` : $t('login.tips_5') }}
            </a-button>
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input-password v-model="registerForm.password" allow-clear :placeholder="$t('login.tips_3')">
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item prop="repassword">
          <a-input-password v-model="registerForm.repassword" allow-clear :placeholder="$t('login.tips_2')">
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: 0;">
          <a-button type="primary" block html-type="submit">
            {{ $t('login.title_2') }}
          </a-button>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: 0;">
          <div class="form-link flex-center-end">
            <span>{{ $t('login.tips_6') }}</span>
            <a-button type="link" class="btn-link" @click="formType = 'login'">
              {{ $t('login.tips_7') }}
            </a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
      <!-- 重置密码 -->
      <a-form-model v-show="formType === 'resetpwd'" :model="resetForm" :rules="resetRules" ref="ResetForm" @submit="handleReset" @submit.native.prevent>
        <a-form-model-item>
          <span>{{ resetForm.type === 'email' ? $t('login.text_2') : $t('login.text_3')}}</span>
          <a-button type="link" class="btn-link" @click="handleVerify">
            {{ resetForm.type === 'email' ? $t('login.tab_1') : $t('login.tab_2')}}
          </a-button>
        </a-form-model-item>
        <a-form-model-item prop="email" v-if="resetForm.type === 'email'">
          <a-auto-complete v-model="resetForm.email" allow-clear :placeholder="$t('login.tips_0')" @search="handleSearch">
            <a-input>
              <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
            </a-input>
            <template slot="dataSource">
              <a-select-option v-for="email in result" :key="email">
                {{ email }}
              </a-select-option>
            </template>
          </a-auto-complete>
        </a-form-model-item>
        <a-form-model-item prop="area" v-if="resetForm.type === 'mobile'">
          <a-select
            show-search
            v-model="resetForm.area"
            :placeholder="$t('login.tips_14')"
            :filter-option="filterOption"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option v-for="item in areaCode" :key="item[`label_${$i18n.locale}`]">
              {{ item[`label_${$i18n.locale}`] }}
            </a-select-option>
            <template #notFoundContent>
              <div class="select-empty">
                <a-empty :image="require('@/assets/search_none.png')">
                  <span slot="description" class="empty-text"> {{$t('common.search_3')}} </span>
                </a-empty>
              </div>
            </template>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="mobile" v-if="resetForm.type === 'mobile'">
          <a-input v-model="resetForm.mobile" type="number" allow-clear :placeholder="$t('login.tips_1')">
            <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="code">
          <a-input class="input-code" v-model="resetForm.code" allow-clear :placeholder="$t('login.tips_4')">
            <a-icon slot="prefix" type="message" style="color:rgba(0,0,0,.25)" />
            <a-button slot="addonAfter" type="link" class="btn-link" :loading="countdownLoading" @click="handleSendCode">
              {{ countingDown ? `${countdown} ${$t('login.tips_12')}` : $t('login.tips_5') }}
            </a-button>
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="pwd">
          <a-input-password v-model="resetForm.pwd" allow-clear :placeholder="$t('login.tips_8')">
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item prop="new_pwd">
          <a-input-password v-model="resetForm.new_pwd" allow-clear :placeholder="$t('login.tips_9')">
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: 10px;">
          <a-button type="primary" block html-type="submit">
            {{ $t('common.ok') }}
          </a-button>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: 10px;">
          <div class="form-link flex-center-end">
            <a-button type="link" class="btn-link" @click="formType = 'login'">
              {{ $t('login.tips_10') }}
            </a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
      <!-- 绑定邮箱 -->
      <a-form-model v-show="formType === 'thirdBind'" :model="thirdBindForm" :rules="thirdBindRules" ref="ThirdBindForm" @submit="handleThirdBind" @submit.native.prevent>
        <a-form-model-item prop="email">
            <a-auto-complete v-model="thirdBindForm.text" allow-clear :placeholder="$t('login.tips_0')" @search="handleSearch">
              <a-input>
                <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
              </a-input>
              <template slot="dataSource">
                <a-select-option v-for="email in result" :key="email">
                  {{ email }}
                </a-select-option>
              </template>
            </a-auto-complete>
          </a-form-model-item>
        <a-form-model-item prop="code">
          <a-input class="input-code" v-model="thirdBindForm.code" allow-clear :placeholder="$t('login.tips_4')">
            <a-icon slot="prefix" type="message" style="color:rgba(0,0,0,.25)" />
            <a-button slot="addonAfter" type="link" class="btn-link" :loading="countdownLoading" @click="handleSendCode">
              {{ countingDown ? `${countdown} ${$t('login.tips_12')}` : $t('login.tips_5') }}
            </a-button>
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="invitecode">
          <a-input v-model="thirdBindForm.invitecode" allow-clear :placeholder="$t('login.tips_11')">
            <a-icon slot="prefix" type="smile" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: 0;">
          <a-button type="primary" block html-type="submit">
            {{ $t('common.ok') }}
          </a-button>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: 0;">
          <div class="form-link flex-center-end">
            <span>{{ $t('login.tips_6') }}</span>
            <a-button type="link" class="btn-link" @click="formType = 'login'">
              {{ $t('login.tips_7') }}
            </a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
      <div class="login-footer" v-show="formType === 'login' || formType === 'register'">
        <span>{{ formType === 'login' ? $t('login.text_1') : $t('login.text_4') }}</span>
        <a-button type="link" class="btn-link" @click="handleLink('Treaty')">
          {{ $t('login.treaty_1') }}
        </a-button>
        <span>{{ $t('login.text') }}</span>
        <a-button type="link" class="btn-link" @click="handleLink('Privacy')">
          {{ $t('login.treaty_2') }}
        </a-button>
      </div>
      <div class="flex-center-column" v-show="thirdLoginVisible">
        <a-divider><span class="other">{{ $t('login.other') }}</span></a-divider>
        <div class="flex">
          <div class="third-signin-btn" v-show="appleScript.loaded" @click="handleAppleLogin">
            <div id="appleid-signin" data-mode="logo-only" data-border-radius="50" style="pointer-events: none;"></div>
          </div>
          <div class="third-signin-btn" v-show="googleScript.loaded">
            <div id="g_id_signin"></div>
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>
<script>
import MD5 from "md5";
import areaCode from '../common/areacode'
import { mapState,mapGetters,mapActions,mapMutations } from 'vuex';
import { AutoComplete,message } from 'ant-design-vue'
import { SendCodeAPI,UserRegisterAPI,UserResetPwdAPI,UserThirdBindAPI } from '../api/api'
import { decodeJwtResponse,loadGoogleSignInScript,initGoogleSignIn,loadAppleSignInScript,initAppleSignIn } from '../utils/thirdSignin'
import { validatorEmail,validatorMobile,validatorPassword } from '../utils/regex'
export default {
  name: 'LoginModal',
  components: {
    [AutoComplete.name]:AutoComplete,
  },
  data() {
    return {
      areaCode:Object.freeze(areaCode),
      result: [],
      timer:null,
      isLoading:false,
      formType:'login',//login register resetpwd thirdBind
      countdown: 60,
      countdownLoading:false,
      countingDown: false,
      loginForm:{
        text: '',
        pwd: ''
      },
      registerForm:{
        text: '',
        code: '',
        invitecode: '',
        password: '',
        repassword: ''
      },
      resetForm:{
        type:'email',
        area:undefined,
        email:'',
        mobile:'',
        code: '',
        pwd: '',
        new_pwd: '',
      },
      thirdBindForm:{
        auth_id:'',
        platform:'',
        text: '',
        code: '',
        invitecode:''
      },
      googleScript:{
        isLoading:false,
        loaded:false,
      },
      appleScript:{
        isLoading:false,
        loaded:false,
      },
    }
  },
  computed: {
    ...mapState(['loginModalVisible','redirect']),
    ...mapGetters('user', ['isLogin']),
    formTitle(){
      const titles = {
        'login': 'title_1',
        'register': 'title_2',
        'resetpwd': 'title_3',
        'thirdBind': 'title_4' // 默认值
      };
      const titleKey = titles[this.formType]
      return this.$t(`login.${titleKey}`);
    },
    thirdLoginVisible(){
      return this.formType !== 'thirdBind'&& this.googleScript.loaded ||this.formType !== 'thirdBind'&&this.appleScript.loaded
    },
    loginRules() {
      return {
        text: [
          { required: true, message: this.$t('login.tips_13'), trigger: 'blur' },
        ],
        pwd: [
          { required: true, message: this.$t('login.tips_3'), trigger: 'blur' },
        ],
      }
    },
    registerRules(){
      return {
        text: [
          { required: true, message: this.$t('login.tips_0'), trigger: 'blur' },
        ],
        code: [
          { required: true, message: this.$t('login.tips_4'), trigger: 'blur' },
        ],
        password: [
          { required: true, message: this.$t('login.tips_3'), trigger: 'blur' },
        ],
        repassword: [
          { required: true, message: this.$t('login.tips_2'), trigger: 'blur' },
        ],
      }
    },
    resetRules(){
      return {
        area: [
          { required: this.resetForm.type === 'mobile', message: this.$t('login.tips_14'), trigger: 'blur' },
        ],
        mobile: [
          { required: this.resetForm.type === 'mobile', message: this.$t('login.tips_1'), trigger: 'blur' },
        ],
        email: [
          { required: this.resetForm.type === 'email', message: this.$t('login.tips_0'), trigger: 'blur' },
        ],
        code: [
          { required: true, message: this.$t('login.tips_4'), trigger: 'blur' },
        ],
        pwd: [
          { required: true, message: this.$t('login.tips_8'), trigger: 'blur' },
        ],
        new_pwd: [
          { required: true, message: this.$t('login.tips_9'), trigger: 'blur' },
        ],
      }
    },
    thirdBindRules(){
      return {
        text: [
          { required: true, message: this.$t('login.tips_0'), trigger: 'blur' },
        ],
        code: [
          { required: true, message: this.$t('login.tips_4'), trigger: 'blur' },
        ],
      }
    }
  },
  watch:{
    "loginModalVisible":{
      immediate:true, // 首次加载的时候执行函数
      deep:false, // 深入观察,监听数组值,对象属性值的变化
      handler:async function(visible){
        if (!visible) return;
        if(!this.appleScript.loaded&&!this.appleScript.isLoading){
          try {
            this.appleScript.isLoading = true
            this.appleScript.loaded = await loadAppleSignInScript()
            this.appleScript.isLoading = false
            initAppleSignIn()
          } catch (error) {
            this.appleScript.isLoading = false
            console.log('apple initialize Failed',error)
          }
        }
        if(!this.googleScript.loaded&&!this.googleScript.isLoading){
          try {
            this.googleScript.isLoading = true
            this.googleScript.loaded = await loadGoogleSignInScript()
            this.googleScript.isLoading = false
            initGoogleSignIn(this.handleGoogleCallback)
          } catch (error) {
            this.googleScript.isLoading = false
            console.log('google initialize Failed',error)
          }
        }
      }
    }
  },
  mounted() {
    const {invitecode} = this.$route.query
    this.registerForm.invitecode = invitecode || sessionStorage.getItem("invitecode") || ''
  },
  methods: {
    ...mapMutations(['LOGIN_MODAL_VISIBLE','SET_REDIRECT','CLEAR_REDIRECT']),
    ...mapActions('user',['PasswordLogin','AuthTokenLogin','ThirdLogin']),
    async handleAppleLogin(){
      try {
        this.isLoading = true
        const {authorization:{id_token}} = await window.AppleID.auth.signIn()
        this.isLoading = false
        this.handleAppleCallback(id_token)
      } catch (error) {
        this.isLoading = false
        console.log('apple login Failed',error)
      }
    },
    async handleAppleCallback(response) {
      const { sub,email } = decodeJwtResponse(response)
      try {
        this.isLoading = true
        await this.ThirdLogin({platform:'apple',auth_id:sub})
        this.formType = 'login'
        this.isLoading = false
        this.LOGIN_MODAL_VISIBLE(false);
        this.handleHasRedirect()
      } catch ({isbind}) {
        this.isLoading = false
        if(!isbind) return
        const {invitecode} = this.$route.query
        const invite = invitecode || sessionStorage.getItem("invitecode") || ''
        const regex = /\@privaterelay\.appleid\.com$/;//是否虚拟邮箱
        this.thirdBindForm = {
          platform:'apple',
          auth_id:sub,
          text: regex.test(email) ? '' : email,
          code: '',
          invitecode:invite
        }
        this.formType = 'thirdBind'
      }
    },
    async handleGoogleCallback(response) {
      if(!response) return message.warning('error')
      // console.log(response, "回调");
      const {sub,email,name,picture,given_name,family_name } = decodeJwtResponse(response.credential);
      try {
        this.isLoading = true
        await this.ThirdLogin({platform:'google',auth_id:sub})
        this.formType = 'login'
        this.isLoading = false
        this.LOGIN_MODAL_VISIBLE(false);
        this.handleHasRedirect()
      } catch ({isbind}) {
        this.isLoading = false
        if(!isbind) return
        const {invitecode} = this.$route.query
        const invite = invitecode || sessionStorage.getItem("invitecode") || ''
        this.thirdBindForm = {
          platform:'google',
          auth_id:sub,
          text: email,
          code: '',
          invitecode:invite
        }
        this.formType = 'thirdBind'
      }
      // console.log("ID: ",sub);
      // console.log("全名：",name);
      // console.log("名字：",given_name);
      // console.log("姓氏：",family_name);
      // console.log("图片网址：",picture);
      // console.log("电子邮件：",email);
    },
    async handleSendCode() {
      if (!this.countingDown) {
        try {
          let params = {}
          if(this.formType === 'register'){
            const { text } = this.registerForm
            if(!validatorEmail(text)){
              message.warning(this.$t('login.tips_15'))
              return
            }
            params = {text,type:'register'}
          }else if(this.formType === 'thirdBind'){
            const { text } = this.thirdBindForm
            if(!validatorEmail(text)){
              message.warning(this.$t('login.tips_15'))
              return
            }
            params = {type:'bind',text}
          }else{
            const {type,area,mobile,email } = this.resetForm
            if(type === 'email'&&!validatorEmail(email)){
              message.warning(this.$t('login.tips_15'))
              return
            }
            if(type === 'mobile'&&!area){
              message.warning(this.$t('login.tips_14'))
              return
            }
            if(type === 'mobile'&&!validatorMobile(mobile)){
              message.warning(this.$t('login.tips_16'))
              return
            }
            params = {
              ...(type === 'mobile' && { area:area.match(/\d+/)[0] }),
              text:type === 'email' ? email:mobile,
              type:'resetpwd',
            }
          }
          this.countdownLoading = true;
          await SendCodeAPI(params)
          this.countdownLoading = false;
          this.countingDown = true;
          this.countdown = 60;
        } catch (error) {
          this.countdownLoading = false;
        }
        this.timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(this.timer);
            this.countingDown = false;
          }
        }, 1000);
      }
    },
    async handleThirdBind(){
      await this.$refs.ThirdBindForm.validate()
      try {
        this.isLoading = true;
        const { auth_id,platform,text, code, invitecode } = this.thirdBindForm
        const params = {
          auth_id,
          platform,
          text,
          code,
          ...(invitecode&&{invitecode}),
        }
        const {data:{token},msg} = await UserThirdBindAPI(params)
        await this.AuthTokenLogin(token)
        message.success(msg)
        this.$refs.ThirdBindForm.resetFields();
        this.formType = 'login'
        this.LOGIN_MODAL_VISIBLE(false);
        this.isLoading = false
        this.handleHasRedirect()
      } catch (error) {
        this.isLoading = false;
      }
    },
    async handleLogin(){
      await this.$refs.LoginForm.validate()
      try {
        this.isLoading = true
        const { text,pwd } = this.loginForm
        await this.PasswordLogin({ text,pwd })
        this.LOGIN_MODAL_VISIBLE(false);
        this.$refs.LoginForm.resetFields();
        this.isLoading = false
        this.handleHasRedirect()
      } catch (error) {
        this.isLoading = false
      }
    },
    async handleRegister(){
      await this.$refs.RegisterForm.validate()
      const {text,code,password,repassword,invitecode} = this.registerForm
      if(password !== repassword) {
        message.warning(this.$t('login.reset'))
        return
      }
      if(!validatorPassword(password)){
        message.warning(this.$t('login.tips_17'))
        return 
      }
      const params = {
        text,
        code,
        password:MD5(password),
        repassword:MD5(repassword),
        ...(invitecode&&{invitecode}),
      }
      try {
        this.isLoading = true
        const {data:{token},msg} = await UserRegisterAPI(params)
        message.success(msg);
        await this.AuthTokenLogin(token)
        this.$refs.RegisterForm.resetFields();
        this.formType = 'login'
        this.LOGIN_MODAL_VISIBLE(false);
        this.isLoading = false
        this.handleHasRedirect()
      } catch (error) {
        this.isLoading = false
      }
    },
    async handleReset(){
      await this.$refs.ResetForm.validate()
      const {type,area,mobile,email,code,pwd,new_pwd } = this.resetForm
      if(pwd !== new_pwd) return message.warning(this.$t('login.reset'))
      if(!validatorPassword(new_pwd)){
        message.warning(this.$t('login.tips_17'))
        return 
      }
      const params = {
        code,
        ...(type === 'mobile' && { area:area.match(/\d+/)[0] }),
        text:type === 'email' ? email:mobile,
        new_pwd:MD5(new_pwd),
      }
      try {
        this.isLoading = true
        const { msg } = await UserResetPwdAPI(params)
        message.success(msg)
        this.$refs.ResetForm.resetFields();
        this.formType = 'login'
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    },
    handleHasRedirect(){
      if(this.redirect){
        const { name } = this.$route
        if(this.redirect.name === name) return location.reload();
        this.$router.push({ ...this.redirect });
        this.CLEAR_REDIRECT()
      }
    },
    handleSearch(value) {
      let result;
      if (!value || value.indexOf('@') >= 0) {
        result = [];
      } else {
        result = ['gmail.com','yahoo.com','icloud.com','hotmail.com','outlook.com'].map(domain => `${value}@${domain}`);
      }
      this.result = result;
    },
    filterOption(input, option){
      return  option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleVerify(){
      this.resetForm.type  = this.resetForm.type === 'email' ? 'mobile' : 'email';
      this.$refs.ResetForm.clearValidate();
    },
    handleLink(name){
      this.LOGIN_MODAL_VISIBLE(false);
      this.$router.push({ name });
    },
    handleCancel(){
      this.LOGIN_MODAL_VISIBLE(false);
      this.isLoading = false
      const { name, meta:{requiresAuth} } = this.$route
      if(!name||requiresAuth&&!this.isLogin) this.$router.replace({ name: 'Home' })
      if(this.redirect) this.CLEAR_REDIRECT()
    }
  },
}
</script>
<style lang="scss">
.ant-select-selection__clear{
  background-color: transparent;
}
.ant-spin-dot-item{
  background-color: $color;
}
.ant-select-dropdown{
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled){
    background-color: rgba(100, 46, 199, 0.1);
  }
}
</style>
<style lang="scss" scoped>
.login-modal{
  .third-signin-btn{
    cursor: pointer;
    margin: 0 16px;
    width: 40px;
    height: 40px;
  }
  .ant-form {
    .form-link{
      span{
        cursor: pointer;
        user-select: none;
      }
    }
    .ant-form-item{
      margin-bottom: 20px;
      /deep/ .ant-input{
        height: 40px;
        border:none;
        border-radius: 3px;
        background-color: #EEEEEE;
        &:focus{
          box-shadow:0 0 0 2px rgba(100, 46, 199, 0.5);
        }
      }
      /deep/ .ant-select{
        
        .ant-select-selection{
          height: 40px;
          border:none;
          border-radius: 3px;
          background-color: #EEEEEE;
          &:focus{
            box-shadow:0 0 0 2px rgba(100, 46, 199, 0.5);
          }
          .ant-select-selection__rendered{
            line-height: 40px;
          }
        }
      }
      /deep/ .ant-select-open .ant-select-selection{
          box-shadow:0 0 0 2px rgba(100, 46, 199, 0.5);
        }
      .input-code{
        /deep/ .ant-input{
          border-radius:3px 0 0 3px;
          &:focus{
            border-radius: 3px;
          }
        }
      }
      /deep/ .ant-input-group-addon{
        border: none;
        background-color: #EEEEEE;
        .ant-btn-loading{
          &::before{
            background-color: transparent;
          }
          .anticon{
            vertical-align:0;
          }
        }
      }
      /deep/ .ant-btn-block{
        height: 42px;
        border-color:$color;
        background-color:$color;
      }
      
    }
  }
  .btn-link{
    height: unset;
    padding: 0;
    color:$color;
  }
  .invite {
    padding: 0 10px;
    color: #222222;
    font-size: 14px;
    font-weight: bold;
    height: 48px;
    margin-bottom: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../assets/invite_bg.png");
  }
  .login-footer{
    font-size: 14px;
  }
  .other{
    color: #666;
    font-size: 14px;
  }
}
</style>